
.az-table{
  @include shadow(3);

  display: table;
  background-color: white;
  border-radius: $border-radius;
  width: 100%;
  position: relative;

  &--help{
    position: absolute;
    width: simple(); height: simple();
    top: half();
    right: half();
    background-color: color(gray6);
    border-radius: 50%;
    text-align: center;
    color: white;
    font-weight: bold;
    cursor: pointer;

  }
}



.az-table-row{
  // display: table-row;
  display: flex;
  position: relative;

  &:not(:last-child) {
    .az-table-col, .az-table-head {
      border-bottom: 1px solid color(gray7);
    }
  }

  .errorMessage {
    position: absolute;
    bottom: quarter();
    left: 0;
    padding: 0 simple();
    font-size: font-size(small1);
    color: color(danger);

    &::before {
      content: '*';
    }

  }

  &--error {
    .az-table-col, .az-table-head {
      padding-bottom: calc(#{simple()} + #{half()});
    }
  }

}

.az-table-head {
  font-weight: bold;
  white-space: normal;
}

.az-table-col, .az-table-head{
  display: table-cell;
  padding: half();
  white-space: normal;
  overflow-wrap: normal;
  position: relative;

  &--right {
    text-align: right !important;
    justify-content: flex-end;
  }

  &--center{
    text-align: center;
  }
}


/*
 * Col Width - Custom width for table columns
 */
@for $i from 1 through $grid-columns {
  .az-table-col-#{$i}, .az-table-head-#{$i} {
    // width: round-width($i, $grid-columns);
    // max-width: round-width($i, $grid-columns);
    width: table-width($i);

    &--right {
      text-align: right !important;
    }

    &--center{
      text-align: center;
    }
  }
}
