//
// Clearfix
//

%clearfix {
  &::after {
    display: table;
    clear: both;
    content: "";
  }
}
