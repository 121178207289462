.az-tabs-group {
  display: flex;
  column-gap: simple();


  &--underline{
    border-bottom: 1px solid color(gray7);
    padding-bottom: half();
    margin: 0 half() half();
  }

  .az-tab {
    cursor: pointer;

    &--active {
      color: color(brand);
    }
  }
}
