.az-fieldset {
  display: flex;
  border: none;
  align-items: flex-start;

  &__title {
    @extend %base-bold-black;

    width: 34%;
    padding: quarter() simple() half() half();
    text-align: right;
  }

  &__content {
    //  @include shadow(3);

    flex-grow: 2;
    width: 66%;
    overflow: hidden;
    border: none;
    border-radius: $border-radius;
  }

}

@include media(palm) {
  .az-fieldset {
    display: block;

    &__title {
      width: 100%;
      text-align: left;
    }

    &__content {
      width: 100%;
    }
  }
}
