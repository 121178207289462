/* recommended css code for vue3-svgicon */
.#{$prefix}icon {
  display: inline-block;
  color: inherit;
  vertical-align: middle;
  fill: none;
  stroke: currentColor;

  &--brand{
    color: color(brand)
  }

  &--success{
    color: color(success);
  }

  &--warning{
    color: color(warning);
  }

  &--danger{
    color: color(danger);
  }

  &--light{
    color: color(light);
  }

  &--dark{
    color: color(dark);
  }

  &--button{
    // Posicionamiento centrado si está en un contenedor con pos: relative o absolute. Ej: .selectorButton__button
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }

  &.#{$prefix}fill {
    fill: currentColor;
    stroke: none;
  }

  &.#{$prefix}up {
    /* default */
    transform: rotate(0deg);
  }

  &.#{$prefix}right {
    transform: rotate(90deg);
  }

  &.#{$prefix}down {
    transform: rotate(180deg);
  }

  &.#{$prefix}left {
    transform: rotate(-90deg);
  }
}
