
          @import "@/modules/core/design/didor/_didor-share.scss";
          @import "@/design/var.scss";
        
.az-avatar {
  $rootClass: &;

  @include shadow(3);

  position: relative;
  overflow: hidden;
  font-weight: font-weight(bold);
  text-align: center;
  user-select: none;
  border-radius: 50%;

  &__image {
    position: relative;
    z-index: 3;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__initials {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    font-size: 0.5em;
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    width: 0.9em;
    height: 0.9em;
    margin: 0 !important;
    transform: translate(-50%, -40%);
  }

  &--link {
    cursor: pointer;

    &:active::after {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 4;
      width: 100%;
      height: 100%;
      content: '';
      background-color: rgba(0, 0, 0, 0.1) !important;
    }
  }
}
