
          @import "@/modules/core/design/didor/_didor-share.scss";
          @import "@/design/var.scss";
        
.bubblePopup{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;

  /* Adding safe area  */
  padding-bottom: env(safe-area-inset-bottom);
  padding-bottom: var(--notch-inset-bottom);
  padding-left: env(safe-area-inset-left);
  padding-left: var(--notch-inset-left);

  /* Hacemos que las celdas dentro del popup de burbuja se destaquen como enlaces */
  .az-cell {
    cursor: pointer;
    line-height: 1.2em;
  }

  &__mask {
    background-color: rgba(255, 255, 255, 0.3);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
  }

  &__content{
    @include shadow(3);

    position: absolute;
    background-color: white;
    border-radius: $border-radius;
    z-index: 2000;
    overflow: hidden;
    color: color(gray1);
    width: fit-content;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: fit-content;

    li {
      padding: 0;
      margin: 0;
      cursor: pointer;
    }
  }

  ul.bubblePopup--listItemMenu {

    li {
      display: flex;
      align-items: center;
      padding: 0 half() half();
    }

    li:not(:last-child) {
      padding-top: half();
    }
  }

}
