.error {
  font-size: font-size(small1);
  line-height: size(0.8);
  color: color(danger);

  &--light{
    font-weight: bold;
    color: color(danger-light);
  }

  &::before{
    content: '* ';
  }

}

@media screen and (min-width: 600px) {

  .error {
    margin-bottom: half();
    text-align: center;
  }
}
