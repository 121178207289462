
          @import "@/modules/core/design/didor/_didor-share.scss";
          @import "@/design/var.scss";
        
.dialog {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);

  &--loading {
    .dialog__wrapper {
      background-image: none;
    }

    .dialog__title {
      animation: flickerAnimation 2s infinite;
    }
  }

  &--warning {
    .dialog__wrapper {
      background-image: radial-gradient(circle at 0% 0%, hsl(31, 100%, 60%), hsl(29, 100%, 35%));
    }
  }

  &--success {
    .dialog__wrapper {
      background-image: radial-gradient(circle at 0% 0%, #00AAA1, #137D79);
    }
  }

  &--danger {
    .dialog__wrapper {
      background-image: radial-gradient(circle at 0% 0%, hsl(9, 86%, 44%), hsl(5, 94%, 28%));
    }
  }

  &__wrapper {
    @include shadow(2);

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    max-width: 350px;
    padding: triple() simple() simple();
    color: white;
    text-align: center;
    background-image: radial-gradient(circle at 0% 0%, color(brand), color(brand-darker));
    border-radius: $border-radius;
    transition: all 0.3s;
  }

  &__support,
  &__close {
    position: absolute;
    top: simple();
    right: half();
    width: double();
    height: double();
    cursor: pointer;
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    ul {
      margin-left: -10%;
      font-size: font-size(h5);
      list-style: none;
    }
  }

  &__icon {
    width: 5rem;
    margin-bottom: double();
    &__scaled {
      width: 3rem
    }
  }

  &__loading {
    margin-bottom: simple();
    font-size: 2rem !important;
  }

  &__title {
    margin-bottom: half();
    font-size: font-size(h4);
    font-weight: font-weight(bold);

    &--onlyTitle {
      font-size: font-size(h5);
    }
  }

  &__errorCode {
    margin-top: simple();
    font-weight: font-weight(bold);
  }

  &__errorInfo {
    margin-top: half();
    font-size: font-size(small1);
  }

  &__footer {
    width: 100%;
    margin-top: double();
  }

  &__buttons {
    display: flex;
    width: 90%;
    margin: 0 auto half();
  }

  &__button, &__button__reload {
    width: 100%;
    height: double();
    margin-right: simple();
    font-weight: font-weight(bold);
    line-height: double();
    cursor: pointer;
    border: solid 1px white;
    border-radius: $border-radius;

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__button__reload {
    height: auto;
    width: 150%;
  }

  &__link {
    text-decoration: underline;
    cursor: pointer;
  }

  :deep() {
    .az-loading__item::before {
      background-color: color(gray6);
    }

    .az-loading__path {
      stroke: color(gray7);
    }
  }
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
