.emptyState {
  // position: fixed;
  // top: 0;
  // left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  // height: 100vh;
  padding: simple();

  .emptyState__img {
    margin-bottom: simple();
  }

  .emptyState__title {
    max-width: 290px;
    margin-bottom: half();
    font-size: font-size(h4);
    font-weight: font-weight(bold);
    text-align: center;
  }

  .emptyState__message {
    max-width: 250px;
    margin-bottom: double();
    color: color(gray3);
    text-align: center;
  }

  .emptyState__button {
    padding-right: double();
    padding-left: double();
    line-height: double();
  }

  &__content {
    padding: 0 half();
  }

  &__item {
    display: flex;
    gap: half();
    width: 100%;

    &__icon {
      flex-grow: 1;
    }

    &__text {
      flex-grow: 11;
    }

    &:not(:last-child){
      margin-bottom: half();
    }
  }
}
