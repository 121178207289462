
          @import "@/modules/core/design/didor/_didor-share.scss";
          @import "@/design/var.scss";
        

.checkbox-container {
  display: flex;
  flex-direction: column;

  &.privacy-policy {
    display: flex;
    justify-content: center;

    .parragraph {
      margin-bottom: simple();
      font-size: font-size(base);
    }

    .checkbox-container {
      display: flex;
      flex-direction: row;
    }

    .fit-content{
      width: fit-content;
      margin-bottom: auto;

      .az-checkbox__input {
        margin: 3px 0 auto 0;
      }

      .az-checkbox__label {
        display: flex;
        font-size: font-size(base);

        span { text-align: left; }
      }
    }
  }

}
