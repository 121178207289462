
          @import "@/modules/core/design/didor/_didor-share.scss";
          @import "@/design/var.scss";
        

.popupFooter {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);

  .cellGroupComponent {
    margin-bottom: 1.5em !important;
  }

  .az-cell-group, .az-cell-group--advancedSettings {
    border-radius: 10px !important;

    &__content{
      margin-bottom: 1.5em !important;
    }
  }

  &__wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1100;
    width: 100vw;
    padding-top: half();

    /* Adding safe area  */
    padding-bottom: env(safe-area-inset-bottom);
    padding-bottom: var(--notch-inset-bottom);
    padding-left: env(safe-area-inset-left);
    padding-left: var(--notch-inset-left);
    background-color: color(gray7);
    border-radius: $border-radius $border-radius 0 0;
  }

  &__title {
    @extend %base-bold-black;

    padding-bottom: simple();
    text-align: center;
  }

  &__select {
    padding: 0 half();

    .az-cell{
      cursor: pointer;
    }
  }

  &__buttons {
    display: flex;
    padding-top: simple();
  }

  &__button {
    @extend %base-normal-black;

    width: 100%;
    height: double();
    line-height: double();
    color: color(gray4);
    text-align: center;
    cursor: pointer;
    border-top: 1px solid $border-color;

    &:not(:last-of-type) {
      border-left: 1px solid $border-color;
    }

    &--cancel{
      font-weight: font-weight(bold);
      color: white !important;
      background-image: radial-gradient(circle at 0% 0%, color(gray6), color(gray4));
    }

    &--brand {
      font-weight: font-weight(bold);
      color: white !important;
      background-image: radial-gradient(circle at 0% 0%, color(brand-light), color(brand));
    }

    &--disabled {
      color: color(gray4) !important;
      background-image: none ;
    }
  }
}

@include media(landscape){
  .popupFooter{
    &__wrapper{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        max-width: 370px;
        height: fit-content;
        margin: auto;
        overflow: hidden;
        border-bottom-right-radius:  $border-radius;
        border-bottom-left-radius:  $border-radius;

        /* Adding safe area  */
        padding-bottom: env(safe-area-inset-bottom);
        padding-bottom: var(--notch-inset-bottom);
        padding-left: env(safe-area-inset-left);
        padding-left: var(--notch-inset-left);


      }
  }
}

