
          @import "@/modules/core/design/didor/_didor-share.scss";
          @import "@/design/var.scss";
        
.dashed-circle-progress {
  position: relative;

  svg{
    transform: rotate(-90deg);
    transform-origin: 50% 50%;

    .progress-ring__circle{
      transition: stroke-dashoffset 0.35s;
    }
  }

}

.textInfo{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: fit-content;
  height: fit-content;
  margin: auto;

  .progress {
    font-size: font-size(h4);
  }

  .label {
    font-weight: bold;
    text-align: center;
  }
}
