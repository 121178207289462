
          @import "@/modules/core/design/didor/_didor-share.scss";
          @import "@/design/var.scss";
        
.inputDoubleRange{
  position: relative;
  padding-bottom: quarter();


  &__bg{
    position: absolute;
    background-color: color(gray8);
    height: 7px;
    border-radius: 7px;
    width: 100%;
    overflow: hidden;

    &--low{
      position: absolute;
      height: 100%;
    }

    &--up{
      position: absolute;
      height: 100%;
    }

  }

  .showValue {
    position: relative;
    height: 1.5em;
    font-weight: bold;
    font-size: font-size(h5);

    &__upper, &__lower{
      position: absolute;
      top: 0;
      width: fit-content;
    }

  }

  input.inputRange[type="range"] {
    position: absolute;
    padding: 0;
    border: none;
    -webkit-appearance: none;
    background-color: transparent;
    height: 7px;
    border-radius: 7px;
    pointer-events: none;

    &::-webkit-slider-thumb{
      @include shadow(2);

      -webkit-appearance: none;
      width: 15px;
      height: 23px;
      border-radius: 2px;
      border: 1px solid color(gray8);
      background: #ffffff;
      pointer-events: auto;
      cursor: pointer;
    }

    &::-moz-range-thumb, &::-ms-thumb {
      @include shadow(2);

      -webkit-appearance: none;
      width: 15px;
      height: 23px;
      border-radius: 2px;
      border: 1px solid color(gray8);
      background: #ffffff;
      pointer-events: all;
      cursor: pointer;
     }

    &::-webkit-slider-runnable-track{
      background: transparent;
      border: none;
    }

    &::-moz-range-track {
      background: transparent;
      border: none;
    }

    &::-ms-track {
      background: transparent;
      border: none;
    }

    &::-ms-fill-lower {
      background: transparent;
      border: none;
    }

    &::-ms-fill-upper {
      background: transparent;
      border: none;
    }

    //
    // Focus
    //
    &:focus {
      outline: 0;

      &::-webkit-slider-runnable-track {
        background: transparent;
      }

      &::-ms-fill-lower {
        background: transparent;
      }

      &::-ms-fill-upper {
        background: transparent;
      }
    }

    //
    // Disabled
    //
    &:disabled {
      &::-webkit-slider-thumb,
      &::-moz-range-thumb,
      &::-ms-thumb,
      &::-webkit-slider-runnable-track,
      &::-ms-fill-lower,
      &::-ms-fill-upper {
        cursor: not-allowed;
      }
    }
  }

  .inputRange{
    width: 100%;

  }


}

.accesibility{
  display: flex;
  width: 100%;

  &__item{
    & > * {
      position: relative;
      border:0;
      clip: rect(1px, 1px, 1px, 1px);
      clip-path: inset(50%);
      height: 1px;
      margin: -1px;
      width: 1px;
      overflow: hidden;
    }
  }
}
