
          @import "@/modules/core/design/didor/_didor-share.scss";
          @import "@/design/var.scss";
        
/* AZ TABS
*  TODO: llevarse el CSS a un componente Tabs
*/

.az-tabs{
  @include shadow(3);

  position: relative;
  display: flex;
  width: 100%;
  margin-bottom: simple();
  overflow: hidden;
  background-color: #ffffff;
  border: none;
  border-radius: $border-radius;

  a{
    color: inherit;
  }

}
