/**
 * FORM
 */

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

label,
textarea,
input[type='file'] {
  display: block;
}

input {
  cursor: pointer;
}

input,
textarea {
  height: double();
  padding: 0 simple();
  line-height: double();
  border: 1px solid color(border);

  &:focus {
    border-color: color(brand);
    outline: none;
  }

  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder,
  &:-moz-placeholder {
    color: color(gray3);
    opacity: 1;
  }

  &[disabled],
  &[readonly] {
    color: color(gray4);
    cursor: not-allowed;
    opacity: 1;
  }
}

input[type='radio'],
input[type='checkbox'] {
  position: relative;
  bottom: 1px;
  margin-right: half();
  vertical-align: middle;
}
