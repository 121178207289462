
          @import "@/modules/core/design/didor/_didor-share.scss";
          @import "@/design/var.scss";
        

.az-input {
  $rootClass: &;

  position: relative;
  font-size: font-size(base);
  line-height: simple();
  color: color(content);

  &__content {
    display: flex;
    width: 100%;
  }

  &__label {
    flex: none;
    overflow: visible;
    line-height: simple();
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__value {
    position: relative;
    flex: 1;
    // overflow: hidden;
    vertical-align: middle;
  }

  &__body {
    display: flex;
    align-items: center;

    &::after {
      content: v-bind(localInputAppend);
      margin-left: v-bind(inputAppendGap);
      // content: v-bind(localInputAppend);
      display: inline;
      color: color(brand);
      white-space: nowrap;
    }
  }

  &__control {
    display: block;
    width: 100%;
    margin: 0;
    color: color(content);
    text-align: left;
    resize: none;
    background-color: transparent;



    &[disabled] {
      opacity: .5;
    }

    /* HIDE SPIN ARROWS IN TYPE NUMBER */
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* HIDE SPIN ARROWS IN TYPE NUMBER */
    /* Firefox */
    &[type=number] {
      -moz-appearance: textfield;
    }
  }

  &__left-icon,
  &__right-icon,
  &__password,
  &__clear {
    cursor: pointer;

    svg {
      width: simple();
      height: simple();
      padding: 0 0.1rem 0.1rem;
      margin: 0;
      color: color(gray4);
    }
  }

  &__left-icon {
    margin-left: quarter('-');

    svg {
      color: color(content);
    }
  }

  &__right-icon {
    margin-right: quarter('-');

    svg {
      color: color(brand);
    }
  }

  &__password {
    margin-right: quarter('-');
    svg {
      color: color(gray5);
    }
  }

  &__clear {
    margin-right: quarter();

    svg {
      padding: 0.3rem;
      color: color(gray5);
    }
  }

  &__error-message {
    font-size: font-size(small1);
    color: color(danger);
    // white-space: nowrap;
    width: 100%;

    &::before{
      content: '*';
    }
  }

  &__info-message {
    font-size: font-size(small1);
    color: color(gray4);
    white-space: normal;
  }


  &--required {
    label::before {
      position: absolute;
      left: quarter();
      font-size: font-size(small1);
      color: color(danger);
      content: '*';
    }
  }

  &--error {
    [class$='input__control'] {
      color: color(danger);

      &::placeholder {
        color: color(danger);
      }
    }
  }

  &--brand-theme{
  /* Change the white to any color ;) */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active  {
      background-color: transparent !important;
      -webkit-background-clip: text !important;
      -webkit-text-fill-color: color(gray8) !important;
  }

  .az-input__left-icon,
  .az-input__right-icon,
  .az-input__password,
  .az-input__clear {
    cursor: pointer;

    svg {
      color: white !important;
    }
  }


  &:not(:last-child){
    margin-bottom: 2px;
  }

  &:not(:last-child)::after{
    border-bottom: none !important;
  }
}

}

// Estilos Auth Layout
div.az-input--brand-theme {
  background-color: hsla(0,0%,0%,0.2) !important;
}

input.az-input--brand-theme{
  background-color: transparent !important;
  // background-color: transparent !important;
  // background-image: radial-gradient(circle farthest-corner at 300% 0%, hsla(0,0,0%, 0.2), hsla(0,0,0%, 0.1));
  border: 0;
  border-radius: 0;
  color: color(gray8) !important;

  &::placeholder {
    color: color(gray8) !important;
  }

  [class$='input__control'] {
    color: color(gray8) !important;
    border: 0;
    border-radius: 0;
  }


}



@include media(palm) {
  .az-input {
    // display: flex;
    width: 100%;
    padding: half() half();
    overflow: hidden;
    // background-color: #ffffff;

    &__control {
      height: auto;
      padding: 0;
      line-height: simple();
      border: 0;
    }

    &:not(:last-child)::after {
      position: absolute;
      right: 0;
      bottom: 0;
      left: quarter();
      pointer-events: none;
      content: ' ';
      border-bottom: 1px solid color(border);
      -webkit-transform: scaleY(0.5);
      transform: scaleY(0.5);
    }

    &--brand-theme:not(:last-child)::after {
      display: none;
    }
  }
}

