.blockquote--bordered {
  padding-left: simple();
  border-left: 0.15rem solid color(brand);
}

.blockquote--left {
  text-align: left;
}

.blockquote--center {
  text-align: center;
}

.blockquote--right {
  text-align: right;

  &.blockquote--bordered {
    padding-right: simple();
    padding-left: 0;
    border-right: 0.15rem solid color(brand);
    border-left: 0;
  }
}

@include media(portrait) {
  .blockquote--bordered {
    padding-left: half();
  }

  .blockquote--right {
    &.blockquote--bordered {
      padding-right: half();
    }
  }
}
