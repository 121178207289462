// Code block
code,
pre {
  font-family: font-family(monospace);
  font-size: font-size(small2);
  font-weight: font-weight(normal);
  line-height: 1.2rem;
  background-color: color(gray7);
}

pre {
  position: relative;
  display: block;
  padding: half() simple();
  overflow: auto;
  hyphens: none;
  text-align: left;
  word-break: normal;
  word-wrap: normal;
  tab-size: 4;
  white-space: pre;
  word-spacing: normal;

  a > & {
    padding: 0;
    color: inherit;
    background-color: inherit;
  }

  &::after {
    position: absolute;
    top: quarter();
    right: 0;
    display: block;
    height: simple();
    padding: 0 half();
    font-size: 0.6rem;
    font-weight: font-weight(bold);
    line-height: simple();
    color: color(gray4);
    text-align: right;
    text-transform: uppercase;
    letter-spacing: 0.08em;
    content: attr(data-lang);
  }
}

// Inline code
:not(pre) > code {
  padding: 0.1rem 0.3rem;
  font-size: 0.8em;
  line-height: simple();
  color: color(brand-dark);
  white-space: nowrap;
  border-radius: $border-radius;
}

// Keyboard user input
kbd {
  padding: 0.1rem 0.3rem;
  font-family: font-family(monospace);
  font-size: 0.8em;
  line-height: simple();
  color: #ffffff;
  background-color: color(gray2);
  border-radius: $border-radius;
}
