
          @import "@/modules/core/design/didor/_didor-share.scss";
          @import "@/design/var.scss";
        

.modal {

  &__wrapper {
    width: calc(100vw - #{simple()});
    height: fit-content;
    padding: 0;
    margin: auto;
    overflow: hidden;
    background-color: color(white);
    border-radius: $border-radius ;
  }

  &__container{
    max-height: 65vh;
    overflow-y: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar{
      display: none;
    }
  }

  &__title {
    padding: simple() 0 simple();
    font-weight: bold;
    text-align: center;
  }

  &__subtitle {
    margin-bottom: half();
    font-size: font-size(h4);
    font-weight: font-weight(bold);
    text-align: center;
    padding: 0 half();

    &--danger{
      color: color(danger);
    }

    &--brand{
      color: color(brand);
    }

    &--warning{
      color: color(warning);
    }
  }

  &__section {

    ::v-deep ul, ol {
      margin: 0 auto half();
      padding: half() simple() half();
      text-align: left;
      border-radius: $border-radius;
      width: 100%;

      &.bg-gray {
        background-color: color(gray8);
      }

      & > li {
        & > ul {
          list-style-type: '- ';
          padding: 0;
          margin: 0;
        }
      }

    }

    &--message{
      padding: 0 half();
      margin: 0 auto;
      margin-bottom: half();
      color: color(gray3);
      font-size: 1.1em;



    }

    &--block {
      padding: 0 half();
      margin: 0 auto;
      color: color(gray3);
      font-size: 1.1em;
      text-align: center;

      &--recommendation {
        &__body{
          // text-align: center;
        }

        &__buttons {

          display: flex;
          justify-content: center;
          align-items: center;
          padding: half();
          margin: 0 auto;
          color: color(gray3);

          &__item {
            margin-right: half();
          }
        }
      }
    }


    &--loading{
      padding-bottom: double();
      text-align: center;
    }

    &--hasButton{
      height: size(3);
    }
  }

  &__img {
    display: block;
    margin: double() auto;
  }

  &__backButton{
    position: absolute;
    top: simple();
    left: simple();
    cursor: pointer;
  }

  &__closeButton{
    position: absolute;
    top: half();
    z-index: 999;
    right: 0.5rem;
    padding: 0 0.5rem 0;
    cursor: pointer;
  }


  &__buttons {
    position: absolute;
    bottom: 0;
    display: flex;
    width: 100%;
    padding-top: simple();
    z-index: 999;
  }

  &__button {
    @extend %base-normal-black;

    width: 100%;
    height: double();
    overflow: hidden;
    line-height: double();
    color: color(gray8) !important;
    text-align: center;
    cursor: pointer;
    border-top: 1px solid $border-color;

    &:not(:last-child) {
      border-right: 1px solid $border-color;
    }

    &--brand{
      background-image: radial-gradient(circle at 0% 0%, color(brand-light), color(brand));
    }

    &--cancel{
      background-image: radial-gradient(circle at 0% 0%, color(default-light), color(default));
    }

    &--danger{
      background-image: radial-gradient(circle at 0% 0%, color(danger-light), color(danger));
    }

    &--warning{
      background-image: radial-gradient(circle at 0% 0%, color(warning-light), color(warning));
    }

    &--success{
      background-image: radial-gradient(circle at 0% 0%, color(success-light), color(success));
    }
  }


  &__footer{
    padding: half();
    font-size: font-size(small1);
    line-height: 1.5em;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0
  }

}


@include media(landscape) {
  .modal{
    &__wrapper {
      max-width: 370px;
    }
  }
}
