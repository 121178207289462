/**
 * USER SELECT
 *
 * Establece de forma global `user-select` como `none`.
 */
html {
  // user-select: none;
  outline: 0;
}

*:not(input) {
  &,
  &::before,
  &::after {
    // user-select: none;
    outline: 0;
    /* stylelint-disable */
    tap-highlight-color: rgba(0, 0, 0, 0);
    tap-highlight-color: transparent;
    /* stylelint-enable */
  }
}
