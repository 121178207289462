@mixin button($color, $background1, $background2, $backgroundHover1, $backgroundHover2) {
  color: $color;
  // background: red;
  background: linear-gradient(to right bottom, $background1, $background2);
  // background-image: radial-gradient(circle at 0% 0%, $background1, $background2);

  @include hover {
    background: linear-gradient(to right bottom, $backgroundHover1, $backgroundHover2);
    // background-image: radial-gradient(circle at 0% 0%, $backgroundHover1, $backgroundHover2);
  }

  .az-loading {
    --color-az-loading: #{$color} !important;
  }

  &:active {
    background-image: linear-gradient(90deg, $backgroundHover1, $backgroundHover2);
    // background-image: radial-gradient(circle at 0% 0%, $backgroundHover1, $backgroundHover2);
  }
}

@mixin button-plain($color, $background, $backgroundHover) {
  color: $color !important;
  background-color: $background;
  background-image: none !important;

  @include hover {
    background-color: $backgroundHover !important;
  }

  .az-loading {
    --color-az-loading: #{$color} !important;
  }

  &:active {
    background-color: $backgroundHover !important;
  }
}

@mixin button-ghost($color, $border, $colorHover, $borderHover) {
  color: $color !important;
  background-color: transparent !important;
  background-image: none !important;
  border: $button-ghost-border-size solid;
  border-color: $border;

  @include hover {
    color: $colorHover !important;
    border-color: $borderHover !important;
  }

  .az-loading {
    --color-az-loading: #{$color} !important;
  }

  &:active:not(:disabled) {
    color: $colorHover !important;
    border-color: $borderHover !important;
  }
}
