.groupButtons {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  border-radius: $border-radius;

  &__item {
    position: relative;
    width: 100%;
    height: size(2.5);
    font-weight: font-weight(bold);
    line-height: size(2.5);
    color: color(gray4);
    text-align: center;
    cursor: pointer;
    background-color: color(gray8);

    &:first-of-type {
      border-radius: $border-radius 0 0 $border-radius;
    }

    &:last-of-type {
      border-radius: 0 $border-radius $border-radius 0;
    }

    &:not(:last-of-type) {
      margin-right: 1px;
    }

    // &::before,
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
    }

    // &::before {
    //   background-image: radial-gradient(circle at 0% 0%, color(brand-light), color(brand-dark));
    //   opacity: 0;
    //   transition: opacity 300ms ease-in-out;
    // }

    &:active::after {
      z-index: 4;
      background-color: rgba(24, 145, 209, 0.2) !important;
    }

    &.select {
      color: #ffffff;
      background-image: radial-gradient(circle at 0% 0%, color(brand-light), color(brand-dark));

      // span {
      //   color: #fff;
      // }

      // &::before {
      //   opacity: 1;
      // }
    }

    // span,
    // .az-icon {
    //   position: relative;
    //   // transition: color 300ms ease-in-out;
    // }

    // span {
    //   font-weight: font-weight(bold);
    // }
  }
}
