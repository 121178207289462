.selectorButton {
  display: flex;
  justify-content: space-around;

  &__item {
    width: 100%;
    // max-width: 20%;
    overflow: hidden;
    color: white;
  }

  &__button {
    @include shadow(2);

    position: relative;
    width: double();
    height: double();
    margin: 0 auto;
    line-height: double();
    text-align: center;
    cursor: pointer;
    background-color: color(gray5);
    border-radius: $border-radius;
    transition: all .5s ease-in;

    &--select {
      @include shadow(1);

      color: #ffffff;
      background-image: radial-gradient(circle at 0% 0%, color(brand-light), color(brand-dark));
    }
  }

  &__title {
    @extend %small1-normal-gray;

    width: 100%;
    padding: 0 0.375rem;
    margin-top: quarter();
    overflow: hidden;
    line-height: 1.1rem;
    text-align: center;
    // text-overflow: ellipsis;
    // white-space: nowrap;
  }
}
