
          @import "@/modules/core/design/didor/_didor-share.scss";
          @import "@/design/var.scss";
        
.az-loading {
  $rootClass: &;

  display: inline-block;
  vertical-align: middle;

  /**
   * Bars loading
   */
  &--bars {
    position: relative;
    display: inline-block;
    height: 1.6em;
    text-align: center;
  }

  &__bar {
    display: inline-block;
    width: 0.3em;
    height: 100%;
    margin: 0 0.065em;
    background-color: color(loading);
    animation: loading-bars 1.2s infinite ease-in-out;
    border-radius: 5px;

    &:nth-of-type(2) {
      animation-delay: -1.1s;
    }
    &:nth-of-type(3) {
      animation-delay: -1s;
    }
    &:nth-of-type(4) {
      animation-delay: -0.9s;
    }
    &:nth-of-type(5) {
      animation-delay: -0.8s;
    }
  }

  @keyframes loading-bars {
    0%,
    40%,
    100% {
      transform: scaleY(0.4);
    }
    20% {
      transform: scaleY(1);
    }
  }

  /**
   * Circles loading
   */
  $circlesLoadingOffset: 187;

  &--circle {
    position: relative;
    display: inline-block;
    width: 1.6em;
    height: 1.6em;
  }

  &__svg {
    width: 1.6em;
    height: 1.6em;
    -webkit-transform-origin: center center;
        -ms-transform-origin: center center;
            transform-origin: center center;
    animation: loading-circle 1.5s linear infinite;
  }

  &__path {
    stroke: color(loading);
    stroke-dasharray: $circlesLoadingOffset;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    stroke-width: 6;
    transform-origin: center;
    animation: loading-circle-dash 1.5s ease-in-out infinite;
  }

  @-webkit-keyframes loading-circle{
    100%{
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }

  @keyframes loading-circle {

    0%{
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100%{
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }

  @keyframes loading-circle-dash {
    0% {
      stroke-dashoffset: $circlesLoadingOffset;
    }
    50% {
      stroke-dashoffset: calc($circlesLoadingOffset / 4);
      transform: rotate(135deg);
    }
    100% {
      stroke-dashoffset: $circlesLoadingOffset;
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes loading-circle-dash {
    0% {
      stroke-dashoffset: $circlesLoadingOffset;
    }
    50% {
      stroke-dashoffset: calc($circlesLoadingOffset / 4);
      transform: rotate(135deg);
    }
    100% {
      stroke-dashoffset: $circlesLoadingOffset;
      transform: rotate(360deg);
    }
  }

  /**
   * Star loading
   */
  &--star {
    position: relative;
    display: inline-block;
    width: 1.6em;
    height: 1.6em;
  }

  &__item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &::before {
      display: block;
      width: 10%;
      height: 30%;
      margin: 0 auto;
      content: '';
      background-color: color(loading);
      border-radius: 1em;
      animation: loading-star 0.9s infinite ease-in-out both;
    }

    &:nth-of-type(2) {
      transform: rotate(40deg);

      &::before {
        animation-delay: -0.8s;
      }
    }
    &:nth-of-type(3) {
      transform: rotate(80deg);

      &::before {
        animation-delay: -0.7s;
      }
    }
    &:nth-of-type(4) {
      transform: rotate(120deg);

      &::before {
        animation-delay: -0.6s;
      }
    }
    &:nth-of-type(5) {
      transform: rotate(160deg);

      &::before {
        animation-delay: -0.5s;
      }
    }
    &:nth-of-type(6) {
      transform: rotate(200deg);

      &::before {
        animation-delay: -0.4s;
      }
    }
    &:nth-of-type(7) {
      transform: rotate(240deg);

      &::before {
        animation-delay: -0.3s;
      }
    }
    &:nth-of-type(8) {
      transform: rotate(280deg);

      &::before {
        animation-delay: -0.2s;
      }
    }
    &:nth-of-type(9) {
      transform: rotate(320deg);

      &::before {
        animation-delay: -0.1s;
      }
    }
  }

  @keyframes loading-star {
    0%,
    39%,
    100% {
      opacity: 0.2;
    }
    40% {
      opacity: 1;
    }
  }

  /**
   * Blobs loading
   */
  &--blobs {
    position: relative;
    display: inline-block;
    width: 1.6em;
    height: 1.6em;
    margin: 0 0.2em;
  }

  &__blob {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0.55em;
    height: 0.55em;
    border: 0.1em solid color(loading);
    border-radius: 50%;
    transform: translate(-50%, -50%);

    &--top {
      top: 0;
      animation: loading-blob-top 1s infinite ease-in;
    }
    &--bottom {
      top: 100%;
      animation: loading-blob-bottom 1s infinite ease-in;
    }
    &--left {
      left: 0;
      animation: loading-blob-left 1s infinite ease-in;
    }
    &--move {
      top: 0;
      background-color: color(loading);
      animation: loading-blob-move 1s infinite ease-in;
    }

    @keyframes loading-blob-bottom {
      25%,
      50%,
      75% {
        top: 50%;
        left: 100%;
      }
      100% {
        top: 0;
        left: 50%;
      }
    }

    @keyframes loading-blob-left {
      25% {
        top: 50%;
        left: 0;
      }
      50%,
      100% {
        top: 100%;
        left: 50%;
      }
    }

    @keyframes loading-blob-top {
      50% {
        top: 0;
        left: 50%;
      }
      75%,
      100% {
        top: 50%;
        left: 0;
      }
    }

    @keyframes loading-blob-move {
      0%,
      100% {
        top: 0;
        left: 50%;
      }
      25% {
        top: 50%;
        left: 100%;
      }
      50% {
        top: 100%;
        left: 50%;
      }
      75% {
        top: 50%;
        left: 0;
      }
    }
  }

  /**
   * Square loading
   */
  &--square {
    position: relative;
    top: -0.3em;
    display: inline-block;
    width: 1.6em;
    height: 1.6em;
    margin: 0 auto;
  }

  &__square__box {
    position: absolute;
    top: 0;
    left: 0;
    width: 1.6em;
    height: 1.6em;
    background: color(loading);
    border-radius: 0.2em;
    animation: loading-square-box 0.5s linear infinite;
  }

  &__square__shadow {
    position: absolute;
    top: 1.9em;
    left: 0;
    width: 1.75em;
    height: 0.25em;
    background: color(gray1);
    border-radius: 50%;
    opacity: 0.1;
    animation: loading-square-shadow 0.5s linear infinite;
  }

  @keyframes loading-square-box {
    17% {
      border-bottom-right-radius: 3px;
    }
    25% {
      transform: translateY(0.3em) rotate(22.5deg);
    }
    50% {
      border-bottom-right-radius: 1.6em;
      transform: translateY(0.8em) scale(1, 0.9) rotate(45deg);
    }
    75% {
      transform: translateY(0.3em) rotate(67.5deg);
    }
    100% {
      transform: translateY(0) rotate(90deg);
    }
  }

  @keyframes loading-square-shadow {
    50% {
      transform: scale(1.2, 1);
    }
  }
}
