.list--unstyled {
  padding-left: 0;
  list-style: none;
}

.list--inline {
  padding-left: 0;
  list-style: none;

  > li {
    display: inline-block;
    margin-bottom: 0;

    &:not(:last-child) {
      margin-right: simple();

      @include media(palm) {
        margin-right: half();
      }
    }
  }
}
