.az-cell {
  &.draggable {
    position: relative;
    padding-left: double();

    .handle {
      position: absolute;
      top: 0;
      left: 0;
      width: double();
      line-height: double();
      text-align: center;

      .az-icon {
        color: color(gray6);
      }
    }

    &--disabled {
      padding-left: simple();
    }

    // .van-icon-menu {
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   display: block;
    //   width: double($space);
    //   height: double($space);
    //   line-height: double($space);
    //   color: gray(gray4);
    //   text-align: center;
    // }
  }
}

.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  background: #e1e3e4;
  opacity: 0.5;
}
