$sideNavWidth: 90vw;
$sideNavMaxWidth: 310px;

.sideNav {
  position: fixed; /* Stay in place */
  top: 0;
  left: 0;
  z-index: 1000; /* Stay on top */
  width: 100vw;
  visibility: hidden;
  height: 100vh;
  overflow: hidden;
  transform-origin: 0 0;

  // Previene comportamientos extraños con el viewport en vista web en móviles
  body.webView & {
    height: 100%;
  }

  &__byAirzoneLogo {
    position: absolute;
    bottom: calc(#{triple()} + env(safe-area-inset-bottom));
    bottom: calc(#{triple()} + var(--notch-inset-bottom));
    left: simple();
    z-index: 1000;

    svg {
      fill:  color(by-airzone-logo) !important;
    }
  }
}

.sideNav__bar {
  position: absolute;
  top: 0;
  left: 0;
  transform: scaleX(0);
  transform-origin: 0 0;
  transition: transform 0.2s;
  z-index: 1000; /* Stay on top */
  width: $sideNavWidth; /* 0 width - change this with JavaScript */
  max-width: $sideNavMaxWidth;
  height: 100%; /* 100% Full-height */
  padding-top: constant(safe-area-inset-top); // iOS 11
  padding-top: env(safe-area-inset-top); // iOS 11.2+
  padding-top: var(--notch-inset-top);
  padding-bottom: calc(#{double()} + env(safe-area-inset-bottom));
  padding-bottom: calc(#{double()} + var(--notch-inset-bottom));
  background-image: radial-gradient(circle at 0% 0%, color(background-sidenav-start), color(background-sidenav-end));
  border-radius: 0 $border-radius $border-radius 0;
  // overflow-x: hidden; /* Disable horizontal scroll */
}


.sideNav_bar_iOS {
  height: 95vh !important;
}

.sideNav--open {
  visibility: visible;
  // .sideNav__bar {
  //   width: $sideNavWidth;
  //   max-width: $sideNavMaxWidth;
  // }

  .sideNav__bar {
    transform: scaleX(1);
  }

  .sideNav__overlay {
    width: 100%;
    opacity: 1;
  }
}

.sideNav--right {
  .sideNav__bar {
    right: 0;
    left: auto;
    border-radius: $border-radius 0 0 $border-radius;
  }

  .sideNav__openBtn {
    right: half();
    left: auto;
  }

  .sideNav__closeBtn {
    right: auto;
    left: double('-');
  }
}

.sideNav__overlay {
  position: fixed; /* Stay in place */
  top: 0;
  left: 0;
  z-index: 999; /* Stay on top */
  width: 0; /* 0 width - change this with JavaScript */
  height: 100%; /* 100% Full-height */
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
  transition: opacity 0.2s; /* 0.5 second transition effect to slide in the sidenav */
}

.sidenav__logo {
  display: block;
  height: double();
  // height: quadruple();
  padding: 0 simple();
  margin-bottom: simple();
  line-height: double();

  img {
    height: 30px;
    fill: #ffffff;
  }
}

.sideNav__version {
  // position: absolute;
  // top: var(--notch-inset-top);
  // right: simple();
  float: right;
  padding-top: 0.2rem;
  // padding-top: var(--notch-inset-top);
  font-size: font-size(small1);
  line-height: double();
  color: color(sidenav-content);
}

.sidenav__title {
  padding-left: simple();
  font-size: font-size(small1);
  font-weight: font-weight(bold);
  color: color(sidenav-content);
  text-transform: uppercase;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.sideNav__list {
  width: $sideNavWidth;
  max-width: $sideNavMaxWidth;
  height: calc(100vh - 4.5rem);
  overflow: auto;
}

.sidenav__container {
  height: 100%;
}

.sidenav__link {
  display: block;
  padding: 0 simple();
  font-size: font-size(h5);
  line-height: double();
  color: color(sidenav-content);
  cursor: pointer;
}

.sidenav__icon {
  margin-right: half();
  color: color(sidenav-icon);
  vertical-align: -0.25rem;
}

.sidenav__footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(#{double()} + env(safe-area-inset-bottom));
  height: calc(#{double()} + var(--notch-inset-bottom));
  padding-bottom: env(safe-area-inset-bottom);
  padding-bottom: var(--notch-inset-bottom);
  background-color: rgba(0, 0, 0, 0.1);
  &__mobile {
    height: calc(2.5rem + env(safe-area-inset-bottom));
    height: calc(2.5rem + var(--notch-inset-bottom));
  }
}

.sideNav__openBtn {
  position: absolute;
  top: half();
  left: half();
  z-index: 998;
  color: color(brand);
}

.sideNav__closeBtn {
  position: absolute;
  top: env(safe-area-inset-top);
  top: var(--notch-inset-top);
  right: double('-');
  width: double();
  height: double();
  line-height: double() !important;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
}
