
          @import "@/modules/core/design/didor/_didor-share.scss";
          @import "@/design/var.scss";
        
$switch-width: double() !default;
$switch-height: simple() !default;
$switch-background-color: color(white) !default;
$switch-border: 1px solid rgba(0, 0, 0, 0.1) !default;
$switch-node-size: simple() !default;
$switch-node-background-color: color(white) !default;
$switch-node-box-shadow: 0 3px 1px 0 rgba(0, 0, 0, 0.05), 0 2px 2px 0 rgba(0, 0, 0, 0.1),
  0 3px 3px 0 rgba(0, 0, 0, 0.05) !default;
$switch-transition-duration: 300ms !default;
$switch-on-background-color: color(brand) !default;
$switch-disabled-opacity: 0.4 !default;

.az-switch {
  $rootClass: &;

  position: relative;
  box-sizing: initial;
  display: inline-block;
  width: $switch-width;
  height: $switch-height;
  vertical-align: middle;
  cursor: pointer;
  background-color: $switch-background-color;
  border: $switch-border;
  border-radius: $switch-node-size;
  transition: background-color $switch-transition-duration cubic-bezier(0.8, 0, 0.2, 1);

  &__node {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: $switch-node-size;
    height: $switch-node-size;
    background-color: $switch-node-background-color;
    border-radius: 100%;
    box-shadow: $switch-node-box-shadow;
    transition: all $switch-transition-duration cubic-bezier(0.8, 0, 0.2, 1);
  }

  &__loading {
    top: 25%;
    left: 25%;
    width: 50%;
    height: 50%;
    line-height: 1;
  }


  &--on {
    background-color: $switch-on-background-color;

    #{$rootClass}__node {
      transform: translateX($switch-width - $switch-node-size);
    }
  }

  &--indeterminate {
    background-color: $switch-on-background-color;

    #{$rootClass}__node {
      top: 11px;
      height: 0.2rem;
      width: calc($switch-node-size / 2);
      border-radius: 5px;
      transform: translateX(calc(($switch-width - calc($switch-node-size / 2 )) / 2));
    }
  }

  &--disabled {
    cursor: initial;
    opacity: $switch-disabled-opacity;
  }

  &--small {
    width: size(1.5);
    height: size(0.75);

    #{$rootClass}__node {
      width: size(0.75);
      height: size(0.75);
    }

    &#{$rootClass}--on #{$rootClass}__node {
      transform: translateX(size(1.5) - size(0.75));
    }
  }

  &--large {
    width: size(2.5);
    height: size(1.25);

    #{$rootClass}__node {
      width: size(1.25);
      height: size(1.25);
    }

    &#{$rootClass}--on #{$rootClass}__node {
      transform: translateX(size(2.5) - size(1.25));
    }
  }
}
