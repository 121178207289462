.image--fluid {
  width: 100%;
}

.image--frame,
.image--polaroid {
  padding: half();
  background-color: #ffffff;
  border: 1px solid color(border);
  border-radius: $border-radius;
  box-shadow: 0 1px 2px rgba(color('black'), 0.075);
  transition: all 0.2s ease-in-out;
}

.image--polaroid {
  padding-bottom: double();
}
