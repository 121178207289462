/**
* Embed
*
* Credit: Nicolas Gallagher and SUIT CSS.
*/

.embed {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;

  &::before {
    display: block;
    content: '';
  }

  > .embed-item,
  iframe,
  embed,
  object,
  video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
}

.embed--21by9 {
  &::before {
    padding-top: percentage(calc(9 / 21));
  }
}

.embed--16by9 {
  &::before {
    padding-top: percentage(calc(9 / 16));
  }
}

.embed--4by3 {
  &::before {
    padding-top: percentage(calc(3 / 4));
  }
}

.embed--1by1 {
  &::before {
    padding-top: percentage(calc(1 / 1));
  }
}
