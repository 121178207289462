.az-cell {
  display: flex;
  width: 100%;
  padding: half() half();
  overflow: hidden;
  background-color: #ffffff;

  &:not(:last-child)::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: quarter();
    pointer-events: none;
    content: ' ';
    border-bottom: 1px solid color(border);
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }

  &::after {
    left: 0 !important;
    border-bottom: 2px solid color(gray8) !important;
    transform: none !important;
  }

  // .az-cell__value {
  //   overflow: hidden;
  // }

  &--brand {
    .az-cell__body {
      color: color(brand);
    }
  }

  &--error {
    .az-cell__body {
      color: color(danger-light);
    }
  }
}

.loop-cell-container {
  &:not(:last-child) {
    border-bottom: 2px solid color(gray8);
  }
}
