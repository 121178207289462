
          @import "@/modules/core/design/didor/_didor-share.scss";
          @import "@/design/var.scss";
        
.DropdownCell {

  .az-cell {

    .az-icon {
      transition: all 1s linear;
    }


    &--dropdownActive {
      .az-icon {
        transform: rotate(90deg);
        transition: all 1s linear;

      }
    }

  }

}
