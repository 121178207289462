:root {
  --notch-inset-top: env(safe-area-inset-top);
  --notch-inset-right: env(safe-area-inset-right);
  --notch-inset-bottom: env(safe-area-inset-bottom);
  --notch-inset-left: env(safe-area-inset-left);
}

// * {
//   overscroll-behavior: none;
// }

html {
  overflow: hidden;
  // height: 100vh;
  // min-height: 100%; /* [2] */
  // overflow-y: scroll; /* [1] */
  touch-action: manipulation;
  -moz-osx-font-smoothing: grayscale; // sass-lint:disable-line no-vendor-prefixes /* [4] */
  -webkit-font-smoothing: antialiased; // sass-lint:disable-line no-vendor-prefixes /* [4] */
  overscroll-behavior: none;

  // Previene comportamientos extraños con el viewport en vista web en móviles
  &.webView{
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

}

body {
  // height: 100vh;
  // overflow: scroll;
  overscroll-behavior: none;
  overflow: hidden;

  // Previene comportamientos extraños con el viewport en vista web en móviles
  &.webView{
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &.background-transparent {
    background-color: none !important;
    background-image: none !important;
  }

  & > .mainwrapper {
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; /* enables “momentum” (smooth) scrolling */
    padding: 0;
    margin: 0;
    background: transparent;
    scrollbar-width: none;

    &::-webkit-scrollbar{
      display: none;
    }
  }
}

.app {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 100vh;
  padding-top: constant(safe-area-inset-top); // iOS 11
  padding-top: env(safe-area-inset-top); // iOS 11.2+
  padding-top: var(--notch-inset-top);
  overflow-x: hidden;
  overflow-y: hidden;
  padding-bottom: env(safe-area-inset-bottom);
  padding-bottom: env(--notch-inset-bottom);
  padding-left: env(safe-area-inset-left); // iOS 11.2+
  padding-left: var(--notch-inset-left);
  padding-right: env(safe-area-inset-right); // iOS 11.2+
  padding-right: var(--notch-inset-right);
}

// Screen reader only.
.sr-only{
  border:0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  width: 1px;
  overflow: hidden;
  position: absolute !important;
  word-wrap:normal !important;
}

.clickable {
  cursor: pointer;
}

.main {
  height: 100vh;
  padding-top: calc(#{$navBarHeight} + #{quarter()});
  padding-bottom: calc(#{simple()} + #{half()} + #{env(safe-area-inset-bottom)}) ;
  padding-bottom: calc(#{simple()} + #{half()} + #{var(--notch-inset-bottom)}) ;
  overflow: auto;
  scrollbar-width: none;
  // overscroll-behavior: contain;

    &::-webkit-scrollbar{
      display: none;
    }

  // body.webView &{
  //   padding-bottom: calc(#{triple()} + #{var(--notch-inset-bottom)}) ;
  // }

  body.keyboard-up &{
    padding-bottom: calc(16rem + #{var(--notch-inset-bottom)}) ;
    scroll-behavior: smooth;
    // overflow: hidden;
  }
}

body.iOS-platform {
  min-height: 100vh;
}

.app-android-safe-area {
  .main, .main-override {
    padding-bottom: calc(#{double()} + #{var(--notch-inset-bottom)}) ;

  }
}

.main-override {
  body.keyboard-up &{
    padding-bottom: calc(18rem + #{var(--notch-inset-bottom)}) !important;
    scroll-behavior: smooth;
    overflow-y: auto !important;
    scrollbar-width: none;

    &::-webkit-scrollbar{
      display: none;
    }
    // overflow: hidden;
  }
}


@include media(screen){
  #app {
    overflow-x: visible;
  }

}
// .installation-view {
//   .main {
//     padding-bottom: calc(#{double()} + #{var(--notch-inset-bottom)}) ;
//   }
// }

.van-nav-bar {
  position: absolute;
  z-index: 1000;
  padding-top: env(safe-area-inset-top);
  padding-top: var(--notch-inset-top);
}

// Icono de ayuda (?) como superíndice
.help-icon{
  display: inline-block;
  vertical-align: super;
  margin-left: quarter();
  background-color: color(gray4);
  width: .7rem;
  height: .7rem;
  line-height: .7rem;
  text-align: center;
  border-radius: 50%;
  color: white;
  font-size: .6rem;
  font-weight: bold;
  font-family: $font-family-base-2;

  &::after {
    content: '?';
  }
}

.hidde{
  display: none;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.linear-overlay-mask{
  position: absolute;
  bottom: 0;
  z-index: 2000;
  width: 100%;
  height: size(3);
  background: linear-gradient(0deg, rgba(255,255,255,.3) 0%,rgba(255,255,255,.2) 30%, rgba(255,255,255,0) 100%);
  border-radius: 0 0 $border-radius $border-radius;

  &--buttons {
    bottom: double();
  }
}

.no-selectable{
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.selectable{

  -webkit-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
}

.safe-area {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 5;
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: calc(#{double()} + env(safe-area-inset-bottom));
  height: calc(#{double()} + var(--notch-inset-bottom));
  padding-bottom: env(safe-area-inset-bottom);
  padding-bottom: var(--notch-inset-bottom);
  padding-left: env(safe-area-inset-left);
  padding-left: var(--notch-inset-left);
  border-radius: $border-radius $border-radius 0 0;
}

@media screen and (max-width: 361px) {
  html {
    font-size: 87.5%;
  }
}

@media screen and (min-width: 360px) and (max-width: 400px) {
  html {
    font-size: 100%;
  }
}
