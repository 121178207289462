
          @import "@/modules/core/design/didor/_didor-share.scss";
          @import "@/design/var.scss";
        

.sectionHeader{
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: half() quarter();

  &__title{
    font-weight: normal;
    color: color(gray2);
  }

  &__action{
    cursor: pointer;

    &__icon {
      height: size(2);
    }
  }

}
// Estilo alternativo (sólo CSS)
.cellGroup {
  border-radius: v-bind(borderRadius);
  overflow: hidden;

  &__info {
    transform-origin: 0 0; // Se usa para aplicar la animación de acordeón si agregamos un .cellGroup__info
  }

  &__loop{
    border-bottom: 2px solid color(gray8);
  }


  @for $i from 1 through 5 { // Agregar sombra. Ej: (.cellGroup.cellGroup--shadow-3)

    &--shadow-#{$i} {
      @include shadow($i);
    }
  }
}

// Estilos del componente az-cell-group
.az-cell-group {
  display: flex;
  margin-bottom: 0 !important;
  border: none;
  border-radius: $border-radius;
  // border-radius: 5px;

  &__title {
    @extend %base-bold-black;

    width: 33%;
    padding: quarter() simple() half() half();
    font-size: font-size(small1);
    text-align: right;
  }

  &__content {
    @include shadow(3);

    flex-grow: 2;
    margin-bottom: simple();
    overflow: hidden;
    border: none;
    border-radius: $border-radius;
  }

  &__divider {
    background-color: color(gray6);
    color: white !important;
    font-weight: bold;
    padding: quarter() half();
  }

  &--advSettings{
    margin-bottom: none;
    border-radius: 0;

    .az-cell-group__title{
      width: 50%;
      padding: quarter() simple() half() quarter();
      margin-left: quarter();
      font-size: font-size(small1);
      text-align: left;
    }

    .az-cell-group__content{
      flex-grow: 2;
      margin-bottom: 0;
      overflow: hidden;
      border: none;
      border-radius: 0;
      box-shadow: none;
    }
  }
}

@include media(palm) {
  .az-cell-group {
    display: block;

    &__title {
      width: 100%;
      text-align: left;
    }
  }
}

