.groups-view {
  &__zones {
    > div > span {
      display: flex;
      flex: 0 1 auto;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: half('-');
      margin-right: quarter('-');
      margin-left: quarter('-');

      > * {
        flex: 0 0 auto;
        flex-basis: 33.33%;
        flex-grow: 1;
        max-width: 33.33%;
        padding-right: quarter();
        padding-left: quarter();
        margin-top: half();
        margin-left: 0;
      }
    }
  }
}

@include media(palm) {
  .groups-view {
    &__zones {
      > div > span {
        > * {
          flex-basis: 100%;
          max-width: 100%;
        }
      }
    }
  }
}

@include media(lap) {
  .groups-view {
    &__zones {
      > div > span {
        > * {
          flex-basis: 50%;
          max-width: 50%;
        }
      }
    }
  }
}
