
          @import "@/modules/core/design/didor/_didor-share.scss";
          @import "@/design/var.scss";
        

.colorButton{
  &__fill{
    position: relative;
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-image: radial-gradient(circle at 100% 100%,v-bind(startColor) 0%, v-bind(endColor) 100%);

    &--selected{
      &::after{
        position: absolute;
        top: -4px;
        right: 0;
        bottom: 0;
        left: -4px;
        width: 38px;
        height: 38px;
        content: '';
        border: 2px solid v-bind('startColor');
        border-radius: 50%;
      }
    }
  }
}
