.shadow-1 {
  @include shadow(1);
}
.shadow-2 {
  @include shadow(2);
}
.shadow-3 {
  @include shadow(3);
}
.shadow-4 {
  @include shadow(4);
}
.shadow-5 {
  @include shadow(5);
}

.no-shadow {
  box-shadow: none;
}
