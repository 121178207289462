.schedule-view {
  &__wrapper {
    max-width: 700px;
    padding-bottom: calc(#{triple()} + env(safe-area-inset-bottom));
    padding-bottom: calc(#{triple()} + var(--notch-inset-bottom));
    margin: 0 auto;
  }
}

.schedule {
  @include shadow(3);

  margin-bottom: half();
  overflow: hidden;
  background-color: #ffffff;
  border-radius: $border-radius;

  &__header {
    position: relative;
    z-index: 10;
    border-radius: $border-radius;
    box-shadow: 0 2px 4px rgba(var(--color-shadow), 0.08), 0 3px 6px rgba(var(--color-shadow), 0.1);
    transition: all 300ms ease-in-out;

    .az-cell {
      &::after {
        display: none;
      }

      &__label {
        margin-top: quarter();
        line-height: 1rem;
      }
    }
  }

  &__zones {
    max-height: 0;
    overflow: hidden;
    background-color: color(gray8);
    transition: all 300ms ease-in-out;
  }

  &__zone {
    align-items: center;
    padding: quarter() half();
    background-color: color(gray8);

    .az-cell__left-icon {
      min-width: size(3.5);
      padding-right: half();
      padding-left: quarter();
      margin-right: half();
      color: color(gray4);
      text-align: right;
      white-space: nowrap;
      border-right: 1px solid color(gray6);

      span {
        font-size: font-size(small2);
      }
    }

    .az-cell__label {
      line-height: 1.1rem;
    }

    &::after {
      border-color: color(gray7) !important;
    }

    &--auto .az-cell__label {
      color: color(brand);
    }
    &--cool .az-cell__label {
      color: color(brand);
    }
    &--heat .az-cell__label {
      color: color(danger);
    }
    &--fan .az-cell__label {
      color: color(success);
    }
    &--dry .az-cell__label {
      color: color(brand);
    }
    &--powerOff .az-cell__label {
      color: color(gray4);
    }
    &--disabled .az-cell__label {
      opacity: 0.6;
    }
  }

  &--open {
    .schedule__zones {
      max-height: 2000px;
      padding-top: quarter();
    }

    .schedule__zone {
      background-color: color(gray8);
    }
  }
}

.schedule__zones__empty {
  padding: half() 0;
  margin: 0 half();
  font-size: font-size(small1);
  color: color(gray3);
  text-align: center;
  border-top: 1px solid $border-color;
}

.schedule__item {
  position: relative;
  display: flex;
  align-items: center;
  padding: quarter() 0;
  border-top: 1px solid color(gray6);

  &.disabled {
    opacity: 0.6;
  }
}

.schedule__item__time {
  min-width: 90px;
  padding-right: half();
  padding-left: quarter();
  font-size: 18px;
  color: color(gray1);
  text-align: right;
  border-right: 1px solid $border-color;

  span {
    font-size: 0.7em;
    text-transform: uppercase;
  }
}

.schedule__item__action {
  width: 100%;
  padding: 0 half();

  .van-icon {
    width: simple();
    margin-right: quarter();
    font-size: 1.3em;
    text-align: center;
    vertical-align: -0.2em;
  }
}

.schedule__item__name {
  font-size: font-size(h5);
  color: color(gray1);
}

.schedule__item__data {
  font-size: font-size(small1);
  text-transform: uppercase;
}

.schedule__item__name,
.schedule__item__data {
  line-height: 1.4rem;
}

.schedule__item__action__state {
  display: inline-block;
  width: 2.2rem;
}

.schedule__item__action__temp {
  font-size: 18px;
  vertical-align: -1px;
}

.schedule__item__action__unitType {
  margin-left: 0.1rem;
  font-size: 12px;
  vertical-align: -1px;
}

.schedule__item__enable {
  position: absolute;
  top: 0.5rem;
  right: 0;

  .van-switch {
    font-size: 25px !important;
  }
}

.schedule__add__enabled {
  position: relative;
  padding: 0.3rem 0;
  border-bottom: 1px solid color(gray5);

  .van-switch {
    position: absolute;
    top: 0.35rem;
    right: half();
  }
}
