
          @import "@/modules/core/design/didor/_didor-share.scss";
          @import "@/design/var.scss";
        

$navBarHeight: 50px;

.scrolled .main-nav-bar {
  box-shadow: 0 1px 6px rgba(var(--color-shadow), 0.1), 0 4px 20px rgba(var(--color-shadow), 0.15);
}

.nav-bar {

  position: fixed;
  top: 0;
  left: 0;
  z-index: 500 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: calc(#{$navBarHeight} + env(safe-area-inset-top));
  height: calc(#{$navBarHeight} + var(--notch-inset-top));
  padding-top: env(safe-area-inset-top);
  padding-top: var(--notch-inset-top);
  line-height: 1rem;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  background-color: color(background);
  // box-shadow: none;
  transition: box-shadow 300ms ease-in-out;

  img {
    height: 24px;
    cursor: pointer;
  }

  &.scrolled{
    @include shadow(1);
  }
}

.nav-bar__left,
.nav-bar__right {
  width: size(6);
  display: flex;
  align-items: center;
  cursor: pointer;
}

.nav-bar__left {
  justify-content: left;

  .nav-bar__text {
    padding-left: half();
  }

  .nav-bar__icon:first-of-type {
    margin-left: quarter();
  }
}

.nav-bar__right {
  justify-content: right;

  .nav-bar__text {
    padding-right: half();
    color: color(brand);
  }

  .nav-bar__icon:last-of-type {
    margin-right: quarter();
  }

  // Agregamos estilo para un conmutador en esta zona
  .az-switch{
    margin-top: half();
    margin-right: half();
  }

  &--brand {
    .nav-bar__right {
      color: color(brand);
    }
  }
}

.nav-bar__text {
  font-size: font-size(base);
  font-weight: font-weight(normal);
  cursor: pointer;
  transition: color 300ms;
}

.nav-bar__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: size(1.5);
  height: double();

  // &:focus {
  //   outline: 1px solid color(brand);
  // }
}

.nav-bar__title__box {
  padding: 1px 0 1px 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 60%;
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden;
  font-size: font-size(base);
  font-weight: font-weight(bold);
  color: color(gray1);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.nav-bar__title, .nav-bar__subtitle {
  width: 100%;
}

.marginTop {
  margin-top: 0.5rem;
}

.nav-bar__subtitle {
  margin-top: 0.35rem;
  color: color(gray4);
  font-weight: font-weight(normal);

  &--nameEditable {
    margin-top: -0.5rem !important;
  }
}
