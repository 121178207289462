
          @import "@/modules/core/design/didor/_didor-share.scss";
          @import "@/design/var.scss";
        
.az-pill {
  border: 1px solid color(gray4);
  display: inline-block;
  padding: .1rem half();
  border-radius: 15px / 50%;
  font-family: $font-family-base-2;
  white-space: nowrap;

  &--link {
    cursor: pointer;
  }

  &--active {
    background-color: color(gray4);
    color: $color-white;
  }

  &--success {
    border-color: color(success);
    color: color(success);

    &.az-pill--active  {
      background-color: color(success);
      color: $color-white;
    }
  }

  &--warning {
    border-color: color(warning);
    color: color(warning);

    &.az-pill--active  {
      background-color: color(warning);
      color: $color-white;
    }
  }

  &--danger {
    border-color: color(danger);
    color: color(danger);

    &.az-pill--active  {
      background-color: color(danger);
      color: $color-white;
    }
  }

  &--brand {
    border-color: color(brand);
    color: color(brand);

    &.az-pill--active  {
      background-color: color(brand);
      color: $color-white;
    }
  }
}
