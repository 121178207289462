//
// Tamaño
//
// Modifica el tamaño de un elemento.
//

@each $breakpoint in $breakpoints-list {
  @include media($breakpoint) {
    $breakpoint: get-breakpoint-sufix($breakpoint);

    @each $size, $length in $spacing-map {
      // Obtengo la cadena del tamaño para formar el nombre de la clase
      $size: get-space-sufix($size);

      .width#{$breakpoint}#{$size} {
        width: #{$length} !important;
      }

      .height#{$breakpoint}#{$size} {
        width: #{$length} !important;
      }
    }

    @each $size, $length in $size-percentages {
      .width#{$breakpoint}-#{$size} {
        width: $length !important;
      }

      .height#{$breakpoint}-#{$size} {
        height: $length !important;
      }
    }

    .width#{$breakpoint}-auto {
      width: auto !important;
    }

    .height#{$breakpoint}-auto {
      height: auto !important;
    }

    .max-height#{$breakpoint}-100 {
      max-height: 100% !important;
    }

    .max-width#{$breakpoint}-100 {
      max-width: 100% !important;
    }

    .vw#{$breakpoint}-100 {
      width: 100vw !important;
    }

    .vh#{$breakpoint}-100 {
      height: 100vh !important;
    }

    .max-vw#{$breakpoint}-100 {
      max-width: 100vw !important;
    }

    .max-vh#{$breakpoint}-100 {
      max-height: 100vh !important;
    }
  }
}
