
          @import "@/modules/core/design/didor/_didor-share.scss";
          @import "@/design/var.scss";
        
$tabBar-item-text-color: color(gray4);
$tabBar-item-active-color: color(gray1);
$tabBar-item-inactive-color: color(gray5);

.az-tabBarItem {
  $rootClass: &;

  cursor: pointer;
  position: relative;
  display: flex;
  flex: 0 0 auto;
  flex-basis: 0;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0.2rem 0;
  font-size: font-size(small2);
  color: $tabBar-item-text-color;

  &__icon {
    position: relative;
    display: block;
    margin: 0;

    &--dot {
      &::after {
        position: absolute;
        top: 0.2rem;
        right: 0;
        width: 0.5rem;
        height: 0.5rem;
        content: ' ';
        background-color: color(danger);
        border-radius: 100%;
      }
    }
  }

  &__disabled { // lock icon
    position: absolute;
    bottom: -.1rem;
    right: -.1rem;
    height: fit-content;
    line-height: 0;
    color: color(gray4)
  }

  &__badge {
    position: absolute;
    top: 0;
    right: 0.2rem;
    min-width: 1rem;
    padding: 0 0.2rem;
    font-size: font-size(small2);
    font-weight: font-weight(bold);
    line-height: 0.9rem;
    color: #ffffff;
    text-align: center;
    background-color: color(danger);
    border-radius: 0.4rem;
    transform: translateX(50%);
    transform-origin: 100%;
  }

  &__title {
    position: absolute;
    bottom: 0;
    padding-bottom: 0.1rem;
    line-height: 1rem;
    width: 100%;
    text-align: center;
  }

  &--active {
    color: $tabBar-item-active-color;
    // color: $tabBar-item-text-color;
  }

  &--selected {
    color: color(brand);
  }

  &--title {
    padding-bottom: 1rem;
  }

  &--disabled {
    color: $tabBar-item-inactive-color;
  }
}

@include media(landscape){
  .az-tabBar{
    right: 0;
    left: 0;
    max-width: $palm;
    margin: 0 auto;

  }
}
