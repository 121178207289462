.graphic {

  &__info {
    display: flex;
    justify-content: space-between;
  }

  &__body {
    position: relative;

    .overlay-link {
      padding-left: 1rem;
      padding-bottom: 1rem;
    }

    &__noData{
      position: absolute;
      margin: auto;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      text-align: center;
      width: fit-content;
      height: fit-content;
    }

  }

}

//* OVERRIDE CLASE MAIN EN LANDSCAPE *//
// El padding lateral impide que se visualice la gráfica completa en modo fullscreen

.main {

  &.mainLandscape {
    padding-right: 0;
    padding-left: 0;
  }

}

//* OVERRIDE CLASE MAIN EN LANDSCAPE *//
// El padding lateral impide que se visualice la gráfica completa en modo fullscreen

.main {

  &.mainLandscape {
    padding-right: 0;
    padding-left: 0;
  }

}
