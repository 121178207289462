//
// Contenido desbordante
//
// Modifica como se comporta un elemento cuando su contenido se desborda.
//

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}
