
          @import "@/modules/core/design/didor/_didor-share.scss";
          @import "@/design/var.scss";
        
.az-sorting-cell{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 65px;
  margin-bottom: 0;
  overflow: hidden;
  background-image: linear-gradient(270deg, $color-gray-start 0%, $color-gray-end 100%) !important;
  border-radius: 0;
  box-shadow: inset 1px 2px 2px rgba(255,255,255,.4);

  &--header{
    background-image: linear-gradient(270deg, $color-gray7 0%, white 100%) !important;
    -webkit-box-shadow: 0 11px 8px -5px rgba(0,0,0,0.3);
    box-shadow: 0 11px 8px -5px rgba(0,0,0,0.3);
  }

  &::before{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    content: '';
    background-image: linear-gradient(180deg, rgba(31, 41, 51, 0) 50%, rgba(31, 41, 51, 0.5) 100%);
    opacity: 0.6;
  }

  // .handle{
  //   position: absolute;
  //   width: 70%;
  //   height: 100%;
  // }

  .handleIcon{
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc(#{half()} + #{quarter()});
    margin: auto;
    opacity: .5;
  }

  .handle, .handle--header {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
    width: 50%;
    height: double();
    padding-left: calc(#{half()} + #{quarter()});
    margin: auto;
    line-height: double();
    color: color(gray6);
    text-align: left;
    cursor: pointer;
  }



  .editButton {
    position: absolute;
    top: 0;
    right: half();
    bottom: 0;
    z-index: 10;
    width: double();
    height: double();
    margin: auto;
    line-height: double();
    color: color(gray4);
    text-align: center;
    cursor: pointer;
    background-color: color(gray2);
    border-radius: 5px;

  }

  &--header {
    .editButton{
      color: color(gray4);
      background-color: color(gray8);
    }
  }

  &__content{
    position: absolute;
    width: 100%;
    height: 100%;

    &__title {
      @extend %h5-bold-white;

      position: absolute;
      top: 0;
      bottom: 0;
      z-index: 15;
      width: 100%;
      height: fit-content;
      margin: auto;
      margin-left: half();
      font-weight: bold !important;
      // line-height: 1rem;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently
                          supported by Chrome, Edge, Opera and Firefox */


      .az-sorting-cell--header &{
        color: color(gray2) !important;
      }


      & &--input{
        @extend %h5-bold-white;

        position: relative;
        z-index: 10;
        padding-left: 0;
        font-weight: bold !important;
        line-height: 1rem;
        background-color: transparent;
        border: none;

        .az-sorting-cell--header &{
          color: color(gray2) !important;
        }

      }

      & &--input[disabled="disabled"]{
          z-index: 0;
      }
    }

    &__subtitle{
      position: absolute;
      bottom: 0;
      left: .3rem;
      z-index: 100;
      width: 105%;
      overflow: hidden;
      font-size: font-size(small1);
      color: color(danger);
      text-overflow: ellipsis;
      white-space: nowrap;

      &::before{
        content: '*';
      }

    }
  }

  &--handleActive{
    padding-left: calc(#{simple()} + .5rem);
    cursor: pointer;
  }
}
