/**
 * TABLE
 */

table {
  width: 100%;
  min-width: 100%;
  margin-bottom: simple();
  border-spacing: 0;
  border-collapse: collapse;
}

td,
th {
  padding: quarter();
  vertical-align: top;
  border-bottom: 1px solid transparent;
}

thead th {
  font-weight: font-weight(bold);
  vertical-align: bottom;
  border-top-color: color(border);
}

tbody + tbody {
  border-bottom: 1px solid transparent;
}

th,
td,
tbody + tbody {
  border-bottom-color: color(border);
}
