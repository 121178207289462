
          @import "@/modules/core/design/didor/_didor-share.scss";
          @import "@/design/var.scss";
        
.input-wrapper {
  display: inline-flex;
  // justify-content: space-between;

  input {
    max-width: 50px;
    margin: 7px;
    font-size: simple();
    padding: 0;
    text-align: center;
    vertical-align: middle;
    width: 3rem;
    height: 3.2rem;
    border: 1px solid var(--color-border);
    border-radius: 3px;

    &:focus {
      -webkit-appearance: none;
      box-shadow: 0 0 6px rgba(0,0,0,.5);
      border-color: transparent;
      outline: none;
    }
  }

}
