.az-tabBar {
  height: calc(3.4rem + env(safe-area-inset-bottom)) !important;
  height: calc(3.4rem + var(--notch-inset-bottom)) !important;
  // padding-bottom: calc(0.2rem + env(safe-area-inset-bottom));
  padding-bottom: env(safe-area-inset-bottom);
  padding-bottom: var(--notch-inset-bottom);
  background: #ffffff;
  border-radius: $border-radius $border-radius 0 0;
  box-shadow: 0 -1px 6px rgba(var(--color-shadow), 0.1), 0 -4px 20px rgba(var(--color-shadow), 0.15);
}

.az-tabBar-item--button .az-tabBarItem__title {
  height: double();
  font-size: font-size(base);
  line-height: double();
  color: color(brand);
}

@include media(notch) {
  .az-tabBar {
    padding-right: env(safe-area-inset-right);
    padding-right: var(--notch-inset-right);
    padding-left: env(safe-area-inset-left);
    padding-left: var(--notch-inset-left);
  }
}
