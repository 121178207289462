.airtools-view {
  padding-bottom: var(--notch-inset-bottom);

  &__wrapper {
    max-width: 700px;
    margin: 0 auto;
  }
}

.airtools__group {
  @include shadow(3);

  margin-bottom: simple();
  overflow: hidden;
  background-color: #ffffff;
  border-radius: $border-radius;

  &__header {
    position: relative;
    z-index: 10;
    padding: size(0.75) half();
    font-size: font-size(h5);
    font-weight: font-weight(bold);
    border-radius: $border-radius;
    box-shadow: 0 2px 4px rgba(var(--color-shadow), 0.08), 0 3px 6px rgba(var(--color-shadow), 0.1);
  }

  &__zones {
    padding-top: quarter();
    background-color: color(gray8);
  }

  &__zone {
    align-items: center;
    padding: half() half();
    background-color: color(gray8);

    .az-cell__left-icon {
      padding-right: half();
      padding-left: quarter();
      margin-right: half();
      color: color(gray4);
      border-right: 1px solid color(gray6);
    }

    .az-cell__label {
      line-height: 1.1rem;
    }

    &::after {
      border-color: color(gray7) !important;
    }
  }
}
