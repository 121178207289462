$container-width: (
  'palm': $container-width-palm,
  'lap': $container-width-lap,
  'small': $container-width-small,
  'desk': $container-width-desk,
  'large': $container-width-large,
) !default;

.container,
.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;

  @include media(portrait) {
    padding-right: half();
    padding-left: half();
  }

  @include media(screen) {
    padding-right: simple();
    padding-left: simple();
  }
}

.container {
  @each $breakpoint-name, $container-max-width in $container-width {
    @include media($breakpoint-name) {
      max-width: px2em($container-max-width);
    }
  }
}

.container-fluid {
  max-width: 100%;
}
