// .searchDevice-view {
//   .main {
//     display: flex;
//     flex-direction: column;
//     padding-right: 0;
//     padding-left: 0;
//   }
// }
$stepsHeight: quadruple();

.associate {
  padding-bottom: env(safe-area-inset-bottom);
  padding-bottom: var(--notch-inset-bottom);
  // overflow-y: hidden;

  .popup{
    width: 100%;
    padding: simple();
    background-color: white;

    &__title{
      width: 100%;
      padding-right: 4.5rem;
      padding-left: 4.5rem;
      margin-bottom: half();
      font-weight: font-weight(bold);
      text-align: center;
    }

    &__content{
      .az-cell, .az-input{
        background-color: transparent;
      }
    }

    &__footer{
      margin-top: half();
    }
  }

  &__steps {
    min-height: $stepsHeight;
    padding: 0 size(2.5);
  }

  &__header {
    position: relative;
    // z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 100%;
    padding: 0 double();
    text-align: center;

    .base-medium-black{
      margin-bottom: quarter();
      font-size: size(1.2);
      font-weight: bold;
      color: black;
    }
  }

  &__subheader {
    position: relative;
    // z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 100%;
    padding: half() double() half();
    font-size: font-size(small1);
    color: color(gray3);
    text-align: center;
  }

  &__content {
    width: 100%;
    padding: 0 half() simple();
    padding-bottom: calc(#{simple()} + env(safe-area-inset-bottom));
    padding-bottom: calc(#{simple()} + var(--notch-inset-bottom));
    overflow-x: hidden;
    overflow-y: auto;
    text-align: center;
  }


  &--searchDevices {
    .associate__header {
      min-height: size(2);
      font-size: size(0.8);
      font-weight: bold;
      color: color(gray1)
    }


    .associate__content {
      max-height: calc(100vh - 50px - #{$stepsHeight} - #{size(6)} - var(--notch-inset-top));
      // height: calc(100vh - 120px - #{$stepsHeight} - #{size(6)} - env(safe-area-inset-top) - env(safe-area-inset-bottom));
      // height: calc(100vh - 120px - #{$stepsHeight} - #{size(6)} - var(--notch-inset-top) - var(--notch-inset-top));
    }
  }

  &--editInstallation{
    .associate__content{
      min-height: calc(100vh - 50px - #{$stepsHeight} - #{size(6)} - env(safe-area-inset-top));
      min-height: calc(100vh - 50px - #{$stepsHeight} - #{size(6)} - var(--notch-inset-top));
    }
  }

  &--infoDevices,
  &--searchWifi,
  &--selectGroup,
  &--addZone {
    .associate__header {
      min-height: size(2);
    }

    .associate__content {
      max-height: calc(100vh - 50px - #{$stepsHeight} - #{size(2)} - env(safe-area-inset-top));
      max-height: calc(100vh - 50px - #{$stepsHeight} - #{size(2)} - var(--notch-inset-top));
    }
  }

  &__bluetooth {
    position: relative;
    margin-bottom: half();
    cursor: pointer;

    &__button {
      @include shadow(3);

      position: relative;
      z-index: 10;
      width: triple();
      height: triple();
      margin: 0 auto;
      line-height: triple();
      color: white;
      text-align: center;
      cursor: pointer;
      background-image: radial-gradient(circle at 0% 0%, color(gray5), color(gray3));
      border-radius: 50%;
      transition: all 300ms;
    }

    &__wave {
      position: absolute;
      top: 50%;
      left: 50%;
      width: triple();
      height: triple();
      border-radius: 50%;
      transform: translate(-50%, -50%);
    }

    &--searching {
      .associate__bluetooth__button {
        @include shadow(1);

        background-image: radial-gradient(circle at 0% 0%, color(brand-light), color(brand-dark));
      }

      .associate__bluetooth__wave {
        animation: sonar 3s linear infinite;

        &--2 {
          animation-delay: 1s;
        }
        &--3 {
          animation-delay: 2s;
        }
      }
    }
  }

  &__device {
    @include shadow(3);

    display: flex;
    align-items: center;
    width: 100%;
    height: triple();
    padding: 0 half();
    margin: half() 0;
    cursor: pointer;
    background-color: #ffffff;
    // border-radius: $border-radius;
    transition: all 300ms;
    animation: slide-top 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);

    &__icon {
      padding: 0 half() 0 quarter();
      color: color(gray4);
      border-right: 1px solid color(border);
    }

    &__data {
      flex-grow: 2;
      padding: 0 half();
      text-align: left;
    }

    &__name {
      font-weight: font-weight(bold);
    }

    &__state {
      font-size: font-size(small1);
      color: color(danger);

      &--high {
        color: color(success);
      }

      &--medium {
        color: color(warning);
      }
    }

    &__arrow {
      padding: 0 quarter() 0 half();
      color: color(gray4);
    }
  }
}

@keyframes sonar {
  0% {
    width: triple();
    height: triple();
    background-color: rgba(25, 146, 212, 0.2);
    border-color: rgba(25, 146, 212, 0.4);
  }
  100% {
    width: size(8);
    height: size(8);
    background-color: rgba(25, 146, 212, 0);
    border-color: rgba(25, 146, 212, 0);
  }
}

@keyframes slide-top {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.associate__wifi {
  .az-cell__left-icon svg {
    color: color(gray6);
  }
}
