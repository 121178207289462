
          @import "@/modules/core/design/didor/_didor-share.scss";
          @import "@/design/var.scss";
        
.az-pills-group{

  &--quarter {
    .az-pill {
      margin-right: quarter();
      margin-bottom: quarter();
    }
  }

  &--half {
    .az-pill {
      margin-right: half();
      margin-bottom: quarter();
    }
  }

  &--simple {
    .az-pill {
      margin-right: simple();
      margin-bottom: quarter();
    }
  }

  &--double {
    .az-pill {
      margin-right: double();
      margin-bottom: quarter();
    }
  }

  &--triple {
    .az-pill {
      margin-right: triple();
      margin-bottom: quarter();
    }
  }

  &--quadruple {
    .az-pill {
      margin-right: quadruple();
      margin-bottom: half();
    }
  }

  &--slider {
    display: flex;
    align-items: center;
    // padding-bottom: size(1.5);
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      display: none;
    }
  }

}
