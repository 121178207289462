/**
 * PAGE
 *
 * Estilos de página.
 *
 * 1. Establece de forma global `box-sizing` como `border-box`.
 * 2. Asegura que la página ocupe al menos toda la altura de la pantalla.
 * 3. Fuerza que las barras de navegación estén siempre visibles para evitar los
 *    saltos cuando navegamos a otras páginas que no tienen suficiente contenido
 *    para tener que mostrar la barra de navegación.
 * 4. Previene que algunos navegadores móviles aplique zoom de forma automática
 *    a los textos.
 * 5. Evita que las fuentes en OSX tengan un aspecto más grueso comparadas con
 *    otros navegadores que no renderizan el texto usando antialiaded.

 */
html {
  @include rootsize;

  box-sizing: border-box; /* [1] */
  height: 100%; /* [2] */
  overflow-y: scroll; /* [3] */
  text-size-adjust: 100%; /* [4] */
  -moz-osx-font-smoothing: grayscale; // sass-lint:disable-line no-vendor-prefixes /* [5] */
  -webkit-font-smoothing: antialiased; // sass-lint:disable-line no-vendor-prefixes /* [5] */
  scroll-behavior: smooth;
}

body {
  min-height: 100%;
  overflow-x: hidden;
  font-family: font-family(base);
  font-size: font-size(base);
  font-style: normal;
  font-weight: font-weight(normal);
  line-height: line-height(base);
  color: color(content);
  text-align: left;
  text-rendering: optimizeLegibility;
  letter-spacing: $letter-spacing;
  background-color: color(background);
}

* {
  padding: 0;
  margin: 0;

  &,
  &::before,
  &::after {
    -webkit-font-smoothing: antialiased;
    -webkit-overflow-scrolling: touch;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-size-adjust: none;
    -webkit-touch-callout: none;
    box-sizing: border-box;
  }
}
