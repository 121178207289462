
          @import "@/modules/core/design/didor/_didor-share.scss";
          @import "@/design/var.scss";
        
.az-cell {
  $rootClass: &;

  position: relative;
  font-size: font-size(base);
  line-height: simple();
  color: color(content);
  overflow: hidden;
  padding: 0;
  display: flex;
  height: 100%;
  background-color: #ffffff;
  // align-items: stretch;


  &__container {
    display: flex;
    padding: half() half();
    width: 100%;
    transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

    &.noPadding {
      padding: 0;
    }
  }

  &__swipe-action-btn {
    position: absolute;
    z-index: 10;
    min-height: 100%;
    overflow: hidden;
    display: flex;
    // padding: 0 double();
    align-self: stretch;
    justify-content: center;
    align-items: stretch;
    transform: scaleX(0);
    transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    visibility: hidden;


    &--left {
      top: 0;
      left: 0;
      transform-origin: left;
    }

    &--right {
      top: 0;
      right: 0;
      transform-origin: right;
    }

    * {
      // display: flex;
      align-self: center;
      overflow: hidden;
    }



  }

  &__title {
    flex: none;
    line-height: simple();

    &--ellipsis {
      overflow: hidden  !important;
      text-overflow: ellipsis !important;
      white-space: nowrap !important;
    }
  }

  &__info {
    display: inline-block;
    padding: 0 1rem;
    cursor: pointer;
  }

  &__value {
    position: relative;
    flex: 1;
    // overflow: hidden;
    color: color(gray4);
    vertical-align: middle;
  }

  &__label {
    font-size: font-size(small1);
    color: color(gray4);
    // padding-bottom: 2rem;
  }

  &__body {
    display: flex;
    align-items: center;

    &--selected {
      color: color(brand);
    }
  }

  &__left-icon,
  &__right-icon,
  &__link-icon {
    svg {
      width: simple();
      height: simple();
      padding: 0 0.1rem 0.1rem;
      margin: 0;
      color: color(gray4);
    }
  }

  &__left-icon {
    margin-right: 0.2rem;
    margin-left: quarter('-');

    svg {
      color: color(content);
    }
  }

  &__right-icon {
    margin-left: 0.2rem;

    &--clickMask {
      width: size(4);
      height: size(2);
      position: absolute;
      right: 0;
      top: 0;
      z-index: 100;
      cursor: pointer;
    }
  }



  &__right-icon--separator {
    margin-left: half();
    padding-left: half();
    border-left: color(gray4) 1px solid;
  }

  &__dropdown-icon {
    &--active {
      transform: rotate(-180deg)!important;
    }
  }

  &__link-icon {
    min-width: simple();
    margin-right: quarter('-');
    margin-left: 0.2rem;
    text-align: center;
    transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

    &--dropdown {
      transform: rotate(0deg);
    }

    &--active {
      transform: rotate(-180deg);
    }
    svg {
      color: color(brand)!important;
    }

  }


  &--link {
    cursor: pointer;

    &:active {
      background-color: color(gray7);
    }
  }

  &--header{
    background-color: color(gray7);

    .az-cell__title {
      color: color(gray1);
    }

    .az-cell__value {
      color: color(gray3);
    }
  }

  &--subcell{
    background-color: color(gray8);
  }
  &--showError {
    padding-bottom: calc(#{simple()} + #{quarter()}) !important;
  }

  &--leftActionBtnActive {
    .az-cell__swipe-action-btn {
      visibility: visible;
    }

    .az-cell__swipe-action-btn--left {
      transform: scaleX(1);
    }

    .az-cell__container {
      transform: translateX(#{double() + half()});
    }
  }

  &--rightActionBtnActive {
    .az-cell__swipe-action-btn {
      visibility: visible;
    }

    .az-cell__swipe-action-btn--right {
      transform: scaleX(1);
    }

    .az-cell__container {
      transform: translateX(-#{double() + half()});
    }
  }

  &__errorMessage {
    position: absolute;
    bottom: quarter();
    font-size: font-size(small1);
    color: color(danger);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: calc(100% - #{simple()});

    &::before{
      content: '*';
    }
  }

}

@include media(palm) {
  .az-cell {
    display: flex;
    width: 100%;
    // padding: half() half();
    overflow: hidden;
    background-color: #ffffff;

    &--header{
      background-color: color(gray6);
    }

    &--subcell{
      background-color: color(gray8);
    }

    &:not(:last-child)::after {
      position: absolute;
      right: 0;
      bottom: 0;
      left: quarter();
      pointer-events: none;
      content: ' ';
      border-bottom: 1px solid color(border);
      -webkit-transform: scaleY(0.5);
      transform: scaleY(0.5);
    }
  }
}
