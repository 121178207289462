/**
* Estilo base de los widgets de sitio (que se muestran en el slider)
* NOTA: Todos los slides se mostrarán con la misma altura, ajustándolos a la máxima que tenga el widget más con mayor "height"
* - Si se necesita modificar la altura del slider de widget, tocar la constante $widgetHeight
* - Los estilos de color de fondo y texto del widget se sobreescribirán en los componentes correspondientes, usando el mismo selector de clase
* - RECOMENDACIÓN: El color de fondo del widget se modificará sobreescribiendo el selector ".widget"
* - RECOMENDACIÓN: Si va a ser clickeable el widget, aplicar "cursor: pointer" sobre ".widget__container".
* - RECOMENDACIÓN: cualquier personalización adicional sobre un widget en particular, no incluirla aquí, sino en su hoja de estilos correspondiente
*/

$widgetHeight: size(3.5); /* definimos como constante por si queremos usarla en otros componentes del código */
$widgetWidth: size(14);
$landscapeWidgetWidth: size(15);

.widget{
  position: relative;
  overflow: hidden;
  font-family: $font-family-base-2;
  height: $widgetHeight;

  &__wrapper{
    margin: auto;
    max-width: $widgetWidth;
    padding: quarter() half();
  }

  &__container{
    display: flex;
    justify-content: flex-start;
  }
}

@include media(tablet) {
  .widget{
    &__wrapper {
      max-width: $landscapeWidgetWidth;
    }

    &__container{
      justify-content: space-between;
    }
  }
}

@include media(landscape) {
  .widget{
    &__wrapper {
      max-width: $landscapeWidgetWidth;
    }

    &__container{
      justify-content: space-between;
    }
  }
}
