
          @import "@/modules/core/design/didor/_didor-share.scss";
          @import "@/design/var.scss";
        
$thumb-size: 25px;
$track-height: 7px;

@mixin thumb() {
    // pointer-events: auto;
}

@mixin track() {
  // position: absolute; // Con position absolute no se pintan bien los thumbs en iOS
  width: 100%;
  z-index: 10;
}

input[type="range"]::-moz-range-thumb {
  @include shadow(2);
  background-color: white;
  border-radius: 50%;
  border:none;
  height: $thumb-size;
  width: $thumb-size;
}

@mixin range-thumb {
  @include shadow(2);

  pointer-events: auto;
  z-index: 100;
  cursor: pointer;
  background-color: white;
  height: $thumb-size;
  width: $thumb-size;
  border-radius: 50%;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.inputMultiRange {
    position: relative;
    height: simple();
    width: 100%;

  &__element{
    position: absolute;

  }


  &__bg {
    &__item {
      bottom: 1px;
      border-radius: 3px/50%;
      height: $track-height;
      width: 100%;
      position: absolute;
    }

    &--disabled {
      .inputMultiRange__bg__item{
        opacity: .2;
      }
    }
  }

  &__tooltip{
    position: absolute;
    top: -40px;
    color: black;
    font-family: $font-family-base-2;
    font-size: .90em;
    width: fit-content;
    text-align: center;
    transition: opacity 0.2s ease-in-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &__container {
    bottom: 0;
    position: absolute;
    width: 100%;

    [type="range"].inputMultiRange__input {
      position: absolute;
      bottom:0;
      padding: 0;
      margin: 0;
      width: 100%;
      display: block;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      height: 10px;
      border: none;
      background-color: transparent;
      z-index: 10;

      // Desactivo pointer events sólo para navegaores que no sean Firefox
      @supports not (-moz-appearance: none) {
        pointer-events: none;
      }

      //
      // Disabled
      //
      &:disabled {
        &::-webkit-slider-thumb,
        &::-moz-range-thumb,
        &::-ms-thumb,
        &::-webkit-slider-runnable-track,
        &::-ms-fill-lower,
        &::-ms-fill-upper {
          cursor: not-allowed;
        }
      }

      &--disabled {
        z-index: 5;

        &::-webkit-slider-thumb { background-color: #d0bebe; z-index: 0;}
        &::-moz-range-thumb { background-color: #BEC7D0; z-index: 0; }
        &::-ms-thumb { background-color: #BEC7D0; z-index: 0; }
      }

      &::-webkit-slider-runnable-track{
        background: transparent;
        border: none;
      }

      &::-moz-range-track {
        background: transparent;
        border: none;
      }

      &::-ms-track {
        background: transparent;
        border: none;
      }

      &::-ms-fill-lower {
        background: transparent;
        border: none;
      }

      &::-ms-fill-upper {
        background: transparent;
        border: none;
      }
    }

    &--disabled {
      [type="range"].inputMultiRange__input {
        &::-webkit-slider-thumb { background-color: #BEC7D0}
        &::-moz-range-thumb { background-color: #BEC7D0 }
        &::-ms-thumb { background-color: #BEC7D0 }
      }
    }

    [type=range] {
      &::-webkit-slider-runnable-track { @include track }
      &::-moz-range-track { @include track }
      &::-ms-track { @include track }

      &::-webkit-slider-thumb { @include range-thumb }
      &::-moz-range-thumb,  &::-ms-thumb {
        @include range-thumb;
        pointer-events: all;
      }
    }
  }



}
