
          @import "@/modules/core/design/didor/_didor-share.scss";
          @import "@/design/var.scss";
        
.popupFooter {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);



  .linear-overlay-mask {
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%);
    border-radius: 0;
  }

  &__topRight, &__topLeft {
    width: 25%;
    height: size(1.5);
    position: absolute;
    line-height: size(1.5);
    top: quarter();
    color: color(brand);
    cursor: pointer;
  }

  &__topRight {
    right: 0;
    text-align: right;
    padding-right: half();

    &--disabled {
      color: color(gray3);
      opacity: .5;
      cursor: inherit;
    }
  }

  &__topLeft {
    left: 0;
    text-align: left;
    padding-left: half();
  }

  &__container{
    max-height: 75vh;
    overflow-y: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar{
      display: none;
    }
  }

  &__wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1100;
    width: 100vw;
    padding-top: half();

    /* Adding safe area  */
    padding-bottom: env(safe-area-inset-bottom);
    padding-bottom: var(--notch-inset-bottom);
    padding-left: env(safe-area-inset-left);
    padding-left: var(--notch-inset-left);
    background-color: white;
    border-radius: $border-radius $border-radius 0 0;
  }

  &__title {
    @extend %base-bold-black;

    max-width: 50%;
    margin: 0 auto;
    padding-bottom: simple();
    text-align: center;
  }

  &__bar {
    margin: 0 auto;
    width: size(4);
    height: size(.13);
    border-radius: 2px/50%;
    background-color: color(gray3);
  }

  &__select {
    padding: 0 half();
  }

  &__buttons {
    display: flex;
    padding-top: simple();
  }

  &__button {
    @extend %base-normal-black;

    width: 100%;
    height: double();
    line-height: double();
    color: color(gray4);
    text-align: center;
    cursor: pointer;
    border-top: 1px solid $border-color;

    &:not(:last-of-type) {
      border-left: 1px solid $border-color;
    }

    &--cancel{
      font-weight: font-weight(bold);
      color: white !important;
      background-image: radial-gradient(circle at 0% 0%, color(gray6), color(gray4));
    }

    &--brand {
      font-weight: font-weight(bold);
      color: white !important;
      background-image: radial-gradient(circle at 0% 0%, color(brand-light), color(brand));
    }

    &--disabled {
      color: color(gray4) !important;
      background-image: none ;
    }
  }

  body.keyboard-up &{
    .popupFooter__wrapper {
      padding-bottom: calc(16rem + #{var(--notch-inset-bottom)});
    }
  }
}

@include media(landscape){
  .popupFooter{

    &__bar {
      display: none;
    }

    &__select{
      .az-cell{
        cursor: pointer;
      }
    }

    &__wrapper{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        max-width: 370px;
        height: fit-content;
        margin: auto;
        overflow: hidden;
        border-bottom-right-radius:  $border-radius;
        border-bottom-left-radius:  $border-radius;
      }
  }
}
