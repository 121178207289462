.az-input {
  // display: flex;
  width: 100%;
  padding: half() half();
  overflow: hidden;
  background-color: #ffffff;

  &__simple {
    background-color: transparent;
    margin: 0;
    border: none;
  }

  &--error {
    [class$='input__control']::placeholder {
      color: var(--color-danger-light);
    }
  }

  &__control {
    height: auto;
    padding: 0;
    line-height: simple();
    border: 0;
  }

  &--brand {
    .az-input__control {
      color: color(brand);
    }
  }

  &:not(:last-child)::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: quarter();
    pointer-events: none;
    content: ' ';
    border-bottom: 1px solid color(border);
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }

  &::after {
    left: 0 !important;
    border-bottom: 2px solid color(gray8) !important;
    transform: none !important;
  }
}
