.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  cursor: pointer;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  /* Adding safe area  */
  padding-bottom: env(safe-area-inset-bottom);
  padding-bottom: var(--notch-inset-bottom);
  padding-left: env(safe-area-inset-left);
  padding-left: var(--notch-inset-left);
  background-color: rgba(0, 0, 0, 0.8);
  // transition: all .2s ease-in;

  &__wrapper {
    cursor: default;
    z-index: 1100;
    position: absolute;
    top: 0 ;
    right: 0;
    bottom: 0;
    left: 0;
    width: fit-content;
    height: fit-content;
    padding: 0;
    margin: auto;
    overflow: hidden;

    body.keyboard-up & {
      top: -15rem !important;
    }
  }

}
