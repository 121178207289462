
          @import "@/modules/core/design/didor/_didor-share.scss";
          @import "@/design/var.scss";
        
.az-sorting-group{
  @include shadow(2);

  overflow: hidden;
  border-radius: $border-radius;
}
