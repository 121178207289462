
          @import "@/modules/core/design/didor/_didor-share.scss";
          @import "@/design/var.scss";
        
.az-checkbox-group {
  $rootClass: &;

  // margin-bottom: quarter();

  //
  // Cell State
  //
  &--cell {
    .az-checkbox {
      position: relative;
      flex-direction: row-reverse;
      justify-content: space-between;
      width: 100%;
      padding: half() half();
      overflow: hidden;
      font-size: font-size(base);
      line-height: simple();
      color: color(content);
      background-color: #ffffff;

      &--checked {
        .az-checkbox__mark {
          display: none; // Elimina la marca que arrastra del modo Checbox sin celdas agrupadas.
        }
      }

      &:not(:last-child)::after {
        position: absolute;
        right: 0;
        bottom: 0;
        left: quarter();
        pointer-events: none;
        content: ' ';
        border-bottom: 1px solid color(border);
        -webkit-transform: scaleY(0.5);
        transform: scaleY(0.5);
      }

      &:active::before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 4;
        width: 100%;
        height: 100%;
        content: '';
        background-color: rgba(0, 0, 0, 0.1) !important;
      }

      .az-checkbox__base {
        display: none;
      }

      .az-checkbox__cell {
        display: block;
      }

      .az-checkbox__label {
        flex: none;
        overflow: hidden;
        line-height: simple();
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  // List state
  &--list {
    position: relative;
    width: 100%;
    overflow: hidden;
    font-size: font-size(base);
    line-height: simple();
    color: color(content);

    &.az-checkbox-group--inline {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }

    &.az-checkbox-group--block {
      display: flex;
      flex-direction: column;
    }

    .az-checkbox {

      &--checked {
        .az-checkbox__mark {
          display: none; // Elimina la marca que arrastra del modo Checbox sin celdas agrupadas.
        }
      }

      .az-checkbox__base {
        display: none;
      }

      .az-checkbox__cell {
        display: inline;
      }

      .az-checkbox__label {
        margin-left: quarter();
        overflow: hidden;
        line-height: simple();
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
