:root {
  --dp-overlay-col-padding:  2px;
  --dp-row-column-gap: 5px;
  --dp-border-radius: 10px; /* $border-radius; */
  --dp-row-margin:  5px 0; /*Adjust the spacing between rows in the calendar*/
}

.dp {

  &__theme_light {
    --dp-primary-color: hsl(201, 79%, 46%); /* our $brand color */
  }

  // WRAPPER STYLES
  &__menu {
    @include shadow(3);

    // border-radius: $border-radius;
    overflow: hidden;

    &_inner {
      padding: size(.75);
    }
  }

  // HEADER (weekdays label)
  &__calendar_header {
    color: color(gray5);
    margin-top: half();
    border-top: 1px solid color(gray8);
    padding-top: half();

    &_separator {
      display: none;
    }
  }

  &__calendar_row {
    column-gap: var(--dp-row-column-gap);
  }

  // NAVIGATION BUTTONS hidden
  &--arrow-btn-nav {
    display: none;
  }

  // MONTH/YEAR SELECT (HEADER)
  &__month_year_select {

    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTVweCIgaGVpZ2h0PSI5cHgiIHZpZXdCb3g9IjAgMCAxNSA5IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA1Ny4xICg4MzA4OCkgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+ZG93bjwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJHdcOtYS1kZS1lc3RpbG8iIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJJY29ub3MiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC01MDEuMDAwMDAwLCAtNzQwLjAwMDAwMCkiIGZpbGw9IiMxRjI5MzMiIGZpbGwtcnVsZT0ibm9uemVybyI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik01MDMuMDQ2MTA4LDc0MC4zNjUxMiBMNTA4LjQ3NzQ5OCw3NDYuMDI1NDE4IEw1MTMuOTA2MzcsNzQwLjM2NzczNiBDNTE0LjA5ODMzMyw3NDAuMTY5MTMzIDUxNC4zMDY3NTksNzQwLjA4MDkyMiA1MTQuNTI3NzY2LDc0MC4wNDI4MTYgQzUxNC42MjM3ODYsNzQwLjAyNjI2IDUxNC43MDYzMDMsNzQwLjAyMjM3OSA1MTQuNzc3OTc5LDc0MC4wMjMyNzkgQzUxNC44NDk2NTYsNzQwLjAyMjM3OSA1MTQuOTMyMTczLDc0MC4wMjYyNiA1MTUuMDI4MTkzLDc0MC4wNDI4MTYgQzUxNS4yNDkxOTksNzQwLjA4MDkyMiA1MTUuNDU3NjI2LDc0MC4xNjkxMzMgNTE1LjY0NzA3LDc0MC4zNjUxMiBDNTE2LjExNzY0Myw3NDAuODUxOTQ3IDUxNi4xMTc2NDMsNzQxLjYyOTk1MyA1MTUuNjQ3MDcsNzQyLjExNjc4IEw1MDkuMzQ2NTg5LDc0OC42MzQ4OCBDNTA4Ljg3NjAxNSw3NDkuMTIxNzA3IDUwOC4xMjM5ODUsNzQ5LjEyMTcwNyA1MDcuNjUzNDExLDc0OC42MzQ4OCBMNTAxLjM1MjkzLDc0Mi4xMTY3OCBDNTAwLjg4MjM1Nyw3NDEuNjI5OTUzIDUwMC44ODIzNTcsNzQwLjg1MTk0NyA1MDEuMzUyOTMsNzQwLjM2NTEyIEM1MDEuODIzNTA0LDczOS44NzgyOTMgNTAyLjU3NTUzNCw3MzkuODc4MjkzIDUwMy4wNDYxMDgsNzQwLjM2NTEyIFoiIGlkPSJkb3duIj48L3BhdGg+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=');
    background-size: 10px;
    background-position: 90% 50%;
    background-repeat: no-repeat;

    &:hover {
      background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTVweCIgaGVpZ2h0PSI5cHgiIHZpZXdCb3g9IjAgMCAxNSA5IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA1Ny4xICg4MzA4OCkgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+ZG93bjwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJHdcOtYS1kZS1lc3RpbG8iIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJJY29ub3MiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC01MDEuMDAwMDAwLCAtNzQwLjAwMDAwMCkiIGZpbGw9IiMxRjI5MzMiIGZpbGwtcnVsZT0ibm9uemVybyI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik01MDMuMDQ2MTA4LDc0MC4zNjUxMiBMNTA4LjQ3NzQ5OCw3NDYuMDI1NDE4IEw1MTMuOTA2MzcsNzQwLjM2NzczNiBDNTE0LjA5ODMzMyw3NDAuMTY5MTMzIDUxNC4zMDY3NTksNzQwLjA4MDkyMiA1MTQuNTI3NzY2LDc0MC4wNDI4MTYgQzUxNC42MjM3ODYsNzQwLjAyNjI2IDUxNC43MDYzMDMsNzQwLjAyMjM3OSA1MTQuNzc3OTc5LDc0MC4wMjMyNzkgQzUxNC44NDk2NTYsNzQwLjAyMjM3OSA1MTQuOTMyMTczLDc0MC4wMjYyNiA1MTUuMDI4MTkzLDc0MC4wNDI4MTYgQzUxNS4yNDkxOTksNzQwLjA4MDkyMiA1MTUuNDU3NjI2LDc0MC4xNjkxMzMgNTE1LjY0NzA3LDc0MC4zNjUxMiBDNTE2LjExNzY0Myw3NDAuODUxOTQ3IDUxNi4xMTc2NDMsNzQxLjYyOTk1MyA1MTUuNjQ3MDcsNzQyLjExNjc4IEw1MDkuMzQ2NTg5LDc0OC42MzQ4OCBDNTA4Ljg3NjAxNSw3NDkuMTIxNzA3IDUwOC4xMjM5ODUsNzQ5LjEyMTcwNyA1MDcuNjUzNDExLDc0OC42MzQ4OCBMNTAxLjM1MjkzLDc0Mi4xMTY3OCBDNTAwLjg4MjM1Nyw3NDEuNjI5OTUzIDUwMC44ODIzNTcsNzQwLjg1MTk0NyA1MDEuMzUyOTMsNzQwLjM2NTEyIEM1MDEuODIzNTA0LDczOS44NzgyOTMgNTAyLjU3NTUzNCw3MzkuODc4MjkzIDUwMy4wNDYxMDgsNzQwLjM2NTEyIFoiIGlkPSJkb3duIj48L3BhdGg+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=');
      background-size: 10px;
      background-position: 90% 50%;
      background-repeat: no-repeat;

    }
  }


  // CELL STYLES (DAYS)
  &-custom-cell, &__today {
    border-radius: 50%;
  }


  // ACTION BUTTONS SECTION
  &__action_row {
    padding: 0;
    width: 100%;
    display: block;
  }

  &__action_buttons {
    @extend %base-normal-black;

    width: 100%;
    height: double();
    overflow: hidden;
    line-height: double();
    color: color(gray8) !important;
    text-align: center;
    cursor: pointer;
    border-top: 1px solid $border-color;

    &:not(:last-child) {
      border-right: 1px solid $border-color;
    }
  }

  &__action_button {
    width: 100%;
    height: 100%;
    flex-grow: 1;
    border-radius: 0;
    border: none;
    margin-inline-start: 0;
    text-align: center;
    color: white;
    padding: half();
    display: inline-block;
  }

  &__action_cancel {
    background-image: radial-gradient(circle at 0% 0%, color(gray6), color(gray4)) !important;
  }

  &__action_select {
    background-image: radial-gradient(circle at 0% 0%, color(brand-light), color(brand)) !important;
  }

  // SELECTION PREVIEW
  &__selection_preview {
    display: none;
  }
}

// OVERRIDE OF COMPONENTS STYLES
.calendarSelector {

  .dp {
    &__main{
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      width: 100%;
      margin: 0;
      background-color: transparent;
      border: none;

    }

    &__input_wrap {
      opacity: 0 !important;
    }

  }
}
