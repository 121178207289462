.vue-scroll-picker{
  background-color: color(gray8);
  border-radius: $border-radius;

  .vue-scroll-picker-item{
    font-size: size(0.9);

    &-selected{
      color: color(brand);
    }
  }

  .vue-scroll-picker-layer-top  {
    background: linear-gradient(180deg, color(gray8) 10%, hsla(220, 18%, 97%, 0.7))
  }
  .vue-scroll-picker-layer-bottom {
    background: linear-gradient(0deg, color(gray8) 10%, hsla(220, 18%, 97%, 0.7))

  }
}

.picker-group{
  display: flex;
  background-color: color(gray8);
  border-radius: $border-radius;
}
