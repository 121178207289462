.scene-view {
  &__wrapper {
    max-width: 700px;
    padding-bottom: calc(#{triple()} + env(safe-area-inset-bottom));
    padding-bottom: calc(#{triple()} + var(--notch-inset-bottom));
    margin: 0 auto;
  }
}

.scene {
  align-items: center;
  // padding-top: 0.2rem;
  // padding-right: 0;
  // padding-bottom: 0.2rem;

  &__icon {
    width: size(1.5);
    margin-right: 0.3rem;
    text-align: center;

    .az-icon {
      color: color(gray4);
    }
  }

  &__launch-button {
    width: double();
    height: double();
    line-height: double();
    text-align: center;

    .az-icon {
      color: color(brand);
    }
  }

  &__feedback {
    height: 100%;
    transform: scale(0.25) translate(-50%, -50%) !important;
  }

  .handle {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
  }

  .az-cell__title {
    line-height: 1.2rem;
  }

  .az-cell__label {
    line-height: 1rem;
  }

  .az-cell__right-icon {
    margin-right: 0;
  }

  &--auto .az-cell__label {
    color: color(brand);
  }
  &--cool .az-cell__label {
    color: color(brand);
  }
  &--heat .az-cell__label {
    color: color(danger);
  }
  &--fan .az-cell__label {
    color: color(success);
  }
  &--dry .az-cell__label {
    color: color(brand);
  }
  &--off .az-cell__label {
    color: color(gray4);
  }
}
