$transition-easing: all #{$transition-duration} ease !default;
$transition-bounce: all #{$transition-duration} cubic-bezier(0.25, 1.43, 0.35, 1.69) !default;
$transition-fade-in-linear: opacity #{$transition-duration} cubic-bezier(0.23, 1, 0.32, 1) !default;
$transition-fade-in: all #{$transition-duration} cubic-bezier(0.55, 0, 0.1, 1) !default;
$transition-zoom-in-bottom: transform #{$transition-duration} cubic-bezier(0.23, 1, 0.32, 1),
  opacity #{$transition-duration} cubic-bezier(0.23, 1, 0.32, 1) !default;
$transition-zoom-in-center: all #{$transition-duration} cubic-bezier(0.55, 0, 0.1, 1) !default;
$transition-zoom-in-left: transform #{$transition-duration} cubic-bezier(0.23, 1, 0.32, 1),
  opacity #{$transition-duration} cubic-bezier(0.23, 1, 0.32, 1) !default;
$transition-zoom-in-right: transform #{$transition-duration} cubic-bezier(0.23, 1, 0.32, 1),
  opacity #{$transition-duration} cubic-bezier(0.23, 1, 0.32, 1) !default;
$transition-zoom-in-top: transform #{$transition-duration} cubic-bezier(0.23, 1, 0.32, 1),
  opacity #{$transition-duration} cubic-bezier(0.23, 1, 0.32, 1) !default;

//
// Fade-in-linear
//

.fade-in-linear-transition-enter-active,
.fade-in-linear-transition-leave-active {
  transition: $transition-fade-in-linear;
}

.fade-in-linear-transition-leave-active-from,
.fade-in-linear-transition-leave-active-to {
  opacity: 0;
}

//
// Fade-in-linear
//

.fade-in-transition-enter-active,
.fade-in-transition-leave-active {
  transition: $transition-fade-in;
}

.fade-in-transition-enter,
.fade-in-transition-leave-active {
  opacity: 0;
}

//
// Zoom-in-bottom
//

.zoom-in-bottom-transition-enter-active,
.zoom-in-bottom-transition-leave-active {
  opacity: 1;
  transition: $transition-zoom-in-bottom;
  transform: scaleY(1);
  transform-origin: center bottom;
}

.zoom-in-bottom-transition-enter-from,
.zoom-in-bottom-transition-leave-to {
  opacity: 0;
  transform: scaleY(0);
}

//
// Zoom-in-center
//

.zoom-in-center-transition-enter-active,
.zoom-in-center-transition-leave-active {
  opacity: 1;
  transition: $transition-zoom-in-center;
  transform-origin: center center;
}

.zoom-in-center-transition-enter-from,
.zoom-in-center-transition-leave-to {
  opacity: 0;
  transform: scale(0, 0);
}

//
// Zoom-in-left
//

.zoom-in-left-transition-enter-active,
.zoom-in-left-transition-leave-active {
  opacity: 1;
  transition: $transition-zoom-in-left;
  transform: scale(1, 1);
  transform-origin: top left;
}

.zoom-in-left-transition-enter-from,
.zoom-in-left-transition-leave-to {
  opacity: 0;
  transform: scale(0.45, 0.45);
}

//
// Zoom-in-right
//

.zoom-in-right-transition-enter-active,
.zoom-in-right-transition-leave-active {
  opacity: 1;
  transition: $transition-zoom-in-right;
  transform: scale(1, 1);
  transform-origin: top right;
}

.zoom-in-right-transition-enter-from,
.zoom-in-right-transition-leave-to {
  opacity: 0;
  transform: scale(0.45, 0.45);
}

//
// Zoom-in-top
//

.zoom-in-top-transition-enter-active,
.zoom-in-top-transition-leave-active {
  opacity: 1;
  transition: $transition-zoom-in-top;
  transform: scaleY(1);
  transform-origin: center top;
}

.zoom-in-top-transition-enter-from,
.zoom-in-top-transition-leave-to {
  opacity: 0;
  // Se aplica escala 0.001 para evitar que no se pinte al contraer y expandir zonas.
  transform: scaleY(0.001);
}

//
// Transition tranlations
//

.fromRight-enter-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

.fromRight-enter-from,
.fromRight-leave-to {
  opacity: 0;
  transform: translateX(10px);
}

.fromTop-enter-active,
.fromTop-leave-active {
  transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
}

.fromTop-enter-from,
.fromTop-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}

.fromBottom-enter-active,
.fromBottom-leave-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

.fromBottom-enter-from,
.fromBottom-leave-to {
  opacity: 0;
  transform: translateY(20px);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s
}
.fade-enter-from, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0
}

.grow-enter-active,
.grow-leave-active {
  transition: all 0.2s cubic-bezier(0.55, 0, 0.1, 1);
}

.grow-enter-from,
.grow-leave-to {
  opacity: 0;
  transform: scaleY(0);
}

.slide-enter-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

.slide-leave-active
{
  transition: all 0.2s cubic-bezier(0.55, 0, 0.1, 1);
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(100%);
}

//
// Transition helpers
//
.no-delay{
  transition-delay: 0s !important;
}
