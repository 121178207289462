/**
 * TYPE
 */
$type-transparent-color: rgba(0, 0, 0, 0);

//
// Cabeceras
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: font-family(heading);
  font-style: normal;
  font-weight: $font-weight-heading;
  color: color(heading);
}

h1 {
  font-size: font-size(h1);
  line-height: line-height(h1);
}

h2 {
  font-size: font-size(h2);
  line-height: line-height(h2);
}

h3 {
  font-size: font-size(h3);
  line-height: line-height(h3);
}

h4 {
  font-size: font-size(h4);
  line-height: line-height(h4);
}

h5 {
  font-size: font-size(h5);
  line-height: line-height(h5);
}

h6 {
  font-size: font-size(base);
  line-height: line-height(base);
}

// Parrafos
p {
  font-size: font-size(base);
}

// Bold.
b,
strong {
  font-weight: font-weight(bold);
}

// Italic.
em,
i {
  font-style: italic;
}

// Texto en linea pequeño.
small,
.small {
  font-family: font-family(heading);
  font-size: font-size(small1);
  font-style: normal;
  font-weight: font-weight(normal);
  line-height: line-height(small1);
}

// Texto resaltado
mark,
.mark {
  padding: quarter();
  color: color(mark);
  background-color: color(mark-background);
}

// Caption
.caption {
  font-family: font-family(base);
  font-size: font-size(h5);
  font-style: italic;
  color: color(caption);
}

h1 + .caption,
.font-size-h1 + .caption {
  margin-top: half();
  margin-bottom: simple();
  font-size: font-size(h4);
  font-style: normal;
  line-height: line-height(h5);
}

h2 + .caption,
.font-size-h2 + .caption {
  margin-top: half();
  margin-bottom: simple();
  font-size: font-size(h5);
  font-style: normal;
  line-height: line-height(base);
}

h3 + .caption,
.font-size-h3 + .caption {
  margin-bottom: half();
  font-size: font-size(base);
  font-style: normal;
  line-height: line-height(base);
}

// Abbreviations
abbr[title],
abbr[data-original-title] {
  cursor: help;
  border-bottom: 0;
}

// Quotes.
blockquote,
.blockquote {
  font-size: font-size(h5);
  line-height: line-height(h5);

  p {
    font-size: font-size(h5);
    line-height: line-height(h5);
  }

  p:last-of-type {
    margin-bottom: 0;
  }

  > footer,
  > .footer {
    display: block;
    font-size: font-size(small1);
    font-style: italic;
    color: color(gray3);

    &::before {
      content: '\2014 \00A0'; // em dash, nbsp
    }
  }
}

// Horizontal rule.
hr {
  padding-top: double();
  padding-bottom: double();
  margin: 0;
  background-image: linear-gradient(to bottom, $type-transparent-color 50%, color(border) 50%);
  background-repeat: repeat-x;
  background-position: 0 50%;
  background-size: 100% 0.15rem;
  border: 0;
}

// Ritmo vertical
p,
h1,
h2,
h3,
ul,
ol,
dl,
table,
blockquote,
pre {
  margin-bottom: simple();
}
