.web-container {
  width: 100%;
}

@include media(landscape) { // Si se permite giro de la app en tablets podríamos cambiar a "screen"

  .web-container {
    max-width: $palm;
    margin: 0 auto;
  }
}
