.media {
  display: flex;
  align-items: flex-start;
}

.media__body {
  flex: 1;
  margin-left: simple();
}

.media--middle {
  align-items: center;
}

.media--bottom {
  align-items: flex-end;
}

.media--right {
  flex-direction: row-reverse;
  text-align: right;

  .media__body {
    margin-right: simple();
    margin-left: 0;
  }
}

@include media(palm) {
  .media__body {
    margin-left: half();
  }

  .media--right {
    .media__body {
      margin-right: half();
      margin-left: 0;
    }
  }
}
