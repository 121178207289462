
          @import "@/modules/core/design/didor/_didor-share.scss";
          @import "@/design/var.scss";
        


.popupFooter{

  &__wrapper{
    background-color: white;
  }

}

.manageIcons {
  width: 90%;
  height: auto;
  min-height: auto;
  max-height: 90%;
  border-radius: $border-radius;

  &__title {
    color: color(gray2);
    text-align: center;
    font-weight: bold;
    padding-bottom: simple();
  }

  &__main {
    padding: half() half() half();
  }

  &__list {

    &__item {
      padding: half() 0;
      line-height: double();
      color: color(gray3);
      text-align: center;
      cursor: pointer;
      border-radius: $border-radius;

      &.select {
        color: color(brand);
        // background-color: color(gray8);
      }
    }
  }

}

