// Estilos globales componente Select @override

.Select{

  &--filled{
    padding: .75rem;
    background-color: color(gray8);
    border-radius: 5px;
  }
}
