
          @import "@/modules/core/design/didor/_didor-share.scss";
          @import "@/design/var.scss";
        
.pincode,
.pincode__mask{
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;

}

.pincode {
  position: fixed;
  display: flex;
  align-items: center;
  justify-items: center;
  color: color(gray7);

  &__mask {
    position: absolute;
    background: rgba(0,0,0,.9);
  }

  &__wrapper{
    position: relative;
    z-index: 11000;
    width: 100%;
    overflow: hidden;

    .pincode-input {
      display: flex;
      justify-content: space-evenly;
      margin-bottom: double();

      input {
        width: 3rem;
        padding: 1rem;
        font-size: font-size(h4);
        color: color(gray7);
        text-align: center;
        background-color: transparent;
        border: 1px solid color(gray6);
        border-radius: $border-radius;
      }


      &__digit {
        margin-right: 2px;
      }
    }
  }

  .row{
    display: flex;
    justify-content: space-evenly;
    margin-bottom: simple();
  }

  .pincode-keyboard{


    &__key{
      padding: 1rem 1.5rem;
      margin: 0;
      font-size: font-size(h5);
      text-align: center;
    }
  }



}
