
          @import "@/modules/core/design/didor/_didor-share.scss";
          @import "@/design/var.scss";
        
.colorButtonsSelector{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  row-gap: half();

  &__item{
    text-align: center;
    align-items: center;
    flex: 1 0 15%;
    pointer-events: all;
    cursor: pointer;
  }

  &--disbled{
    .colorButtonsSelector__item{
      opacity: .6;
    }
  }
}
