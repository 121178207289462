
          @import "@/modules/core/design/didor/_didor-share.scss";
          @import "@/design/var.scss";
        
$tabBar-height: double();
$tabBar-background-color: #ffffff;

.az-tabBar {
  $rootClass: &;

  display: flex;
  width: 100%;
  height: $tabBar-height;
  background-color: $tabBar-background-color;

  &--fixed {
    position: fixed;
    bottom: 0;
    left: 0;
  }

  &--safe-area {
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    padding-bottom: var(--notch-inset-bottom);
  }
}
