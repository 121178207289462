
          @import "@/modules/core/design/didor/_didor-share.scss";
          @import "@/design/var.scss";
        

.slideViewModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  background-color: color(background);

   /* Ocultar scroll en Firefox */
   scrollbar-width: none;

  /* Ocultar scroll en Webkit (Chrome, Safari) */
  -webkit-overflow-scrolling: touch;

  /* Ocultar scroll en Webkit (Chrome, Safari, Edge) */
  &::-webkit-scrollbar {
    display: none;
  }

  &__content {
    display: grid;
    grid-template-rows: auto 1fr auto;
    height: 100%;
  }

  &__header {
    position: relative;
    min-height: calc(#{$navBarHeight} + #{var(--notch-inset-top)});
  }

  &__body {
    padding: 0 simple() half();

    &--no-footer {
      padding-bottom: var(--notch-inset-bottom);
    }
  }

  &__footer {
    padding-bottom: var(--notch-inset-bottom);
  }

}


