$colors-available: (
  'gray1': $color-gray1,
  'gray2': $color-gray2,
  'gray3': $color-gray3,
  'gray4': $color-gray4,
  'gray5': $color-gray5,
  'gray6': $color-gray6,
  'gray7': $color-gray7,
  'gray8': $color-gray8,
  'black': $color-black,
  'white': white,
  'brand': $brand,
  'brand-dark': $brand-dark,
  'brand-darker': $brand-darker,
  'brand-light': $brand-light,
  'brand-lighter': $brand-lighter,
  'success': $success,
  'danger': $danger,
  'warning': $warning
);

@each $name, $val in $styles-weights {
  .az-tag-#{$name} {
    font-weight: $val;
  }
}

@each $color-name, $color-val in $colors-available {
  .az-tag-#{$color-name} {
    padding: .1em .3em;
    border-radius: 5px;
    display: inline-block;
    @if ($color-name == 'brand')
    or ($color-name == 'brand-dark')
    or ($color-name == 'brand-darker')
    or ($color-name == 'danger')
    or ($color-name == 'warning')
    or ($color-name == 'gray1')
    or ($color-name == 'gray2')
    or ($color-name == 'gray3')
    or ($color-name == 'gray4')
    or ($color-name == 'gray5'){
      color: white !important;
    }
    background-color: $color-val;
  }
}
