//
// Tipo de posicionamiento
//
// Modifica como se posiciona un elemento.
//
@each $position in ('static', 'relative', 'absolute', 'fixed', 'sticky') {
  .position-#{$position} {
    position: $position !important;
  }
}

//
// Posicionamiento fijo en la parte superior de la pantalla
//
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: $z-index-fixed;
}

//
// Posicionamiento fijo en la parte inferior de la pantalla
//
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $z-index-fixed;
}

//
// Se posiciona de forma fija en la parte superior de la pantalla
// cuando haciendo scroll llega a la parte superior
//
.sticky-top {
  @supports (position: sticky) {
    position: sticky;
    top: 0;
    z-index: $z-index-sticky;
  }
}
