.auth {
  max-width: 450px;
  padding: simple();
  margin: 0 auto;
  scrollbar-width: none;

  &::-webkit-scrollbar{
    display: none;
  }

}

.auth__log__airzone {
  display: block;
  margin: 0 auto;
}

.auth__logo{
  width: 100%;
  display: block;
  max-width: 18rem;
  margin: double() auto double();
  text-align: center;
  font-size: font-size(h1);
  color: color(white);
}

.auth__title {
  text-align: center;
}

.auth__subtitle {
  margin-bottom: simple();
  font-size: font-size(small1);
  color: color(gray4);
  text-align: center;
}

.auth__link {
  font-size: font-size(small1);
  text-decoration: underline;
  color: color(auth-content);
}

.auth__error {
  font-size: font-size(small1);
  line-height: size(0.8);
  color: color(danger);
}

.auth__legal {
  margin-top: half();
  margin-bottom: half();
  font-size: font-size(small1);
  line-height: size(0.8);
  color: color(auth-content);
}

.auth__modes {
  display: flex;
  padding-top: simple();
  margin-top: simple();
  border-top: 1px solid color(border);
}

.auth__modes__button:not(:last-of-type) {
  margin-right: simple();
}
